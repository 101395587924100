import React from 'react';
import { P, currencyName } from '../../../../../constants/DevSwitch'
import './ResearchMenuRow.css';

const classNames = require('classnames'); 

const ResearchMenuRow = ( {cat, inventory, numUpgrades, increaseCatUpgrades, closeConsoleButton, removeFromInventory, updateTitle, revealMusicButton, changeTutorial} ) => {

	const upgradeIndex = numUpgrades[cat] || 0;			// how many upgrades have been previously bought?
	const costArray = P[cat].cost[upgradeIndex];		// gives either an array of two or an array of four (write code for four later)

	const currencyNum1 = costArray[0];
	const currencyType1 = costArray[1];
	let currencyName1 = currencyName(currencyType1);

	if (currencyType1 === "crop") {
		currencyName1 = currencyName("crop", numUpgrades.ber || 0)
	}

	let moreThanOneCost;
	if (costArray.length>2) {
		moreThanOneCost = true;
	} else {moreThanOneCost = false}

	let currencyNum2;
	let currencyType2;
	let currencyName2;
	if (moreThanOneCost) {
		currencyNum2 = costArray[2];
		currencyType2 = costArray[3];
		currencyName2 = currencyName(currencyType2);
	}

	let textdiv = "";
	let canAffordCurr1 = false;
	let canAffordCurr2 = true;

	if (inventory[currencyType1] >= currencyNum1) {
		canAffordCurr1 = true;
	}

	if (moreThanOneCost && inventory[currencyType2] < currencyNum2) {
		canAffordCurr2 = false;
	}

	if (!moreThanOneCost) {
		textdiv = 	<div className="upgradeText unselectable">
						{P[cat].text[upgradeIndex]} 
						<div className="researchDescription researchDescriptionLarge">(cost: <span className={classNames(canAffordCurr1 ? null : "redtext")}>{currencyNum1}</span> {currencyName1}) </div>
					</div>
	} else {
		textdiv = 	<div className="upgradeText unselectable">
						{P[cat].text[upgradeIndex]} 
						<div className="researchDescription researchDescriptionLarge">
							(cost: <span className={classNames(canAffordCurr1 ? null : "redtext")}>{currencyNum1}</span> {currencyName1} and <span className={classNames(canAffordCurr2 ? null : "redtext")}>{currencyNum2}</span> {currencyName2})
						</div>
					</div>
	}

	const buy = () => {

		switch (cat) {
			case "jamband":
				revealMusicButton();
				changeTutorial(9);
				break;
			case "pas":
				increaseCatUpgrades("fer", "pas");
				break;
			default:
				increaseCatUpgrades(cat);
		}

		if (P[cat].title[upgradeIndex]) {
			updateTitle(P[cat].title[upgradeIndex], P[cat].clicker[upgradeIndex]);
		}
		closeConsoleButton();
		if (moreThanOneCost) {
			removeFromInventory(currencyType1, currencyNum1, currencyType2, currencyNum2);
		} else {
			removeFromInventory(currencyType1, currencyNum1);
		}
	}
	
	let canAfford = (canAffordCurr1 && canAffordCurr2);
	let buttontext = "Can't Afford";
	if (canAfford) {buttontext="Purchase"};

	return (
			<div className="upgradeRow" id={cat+"PurchaseRow"} key={cat+"PurchaseRow"}>
				<button 
					type="button" 
					className="purchaseButton" 
					id={cat+"PurchaseButton"} 
					disabled={!canAfford}
					onClick={buy}
					tabIndex={-1}
					>
						{buttontext}
				</button>
				{textdiv}
			</div>
		);
}

export default ResearchMenuRow;