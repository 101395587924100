import React from 'react';
import './Clicker.css';

const Clicker = ( props ) => {

	return (
		<div id="buttonBox">
  			<button 
  				id="collect" 
  				disabled={props.timerRunning} 
  				onClick={props.timerStart}
  				tabIndex={1}>
  				{props.clickerText}
  			</button>
		</div>
	);
}

export default Clicker;