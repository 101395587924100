import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import './styles/animations.css';
import App from './App';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// EXTRA UTILITIES IMPORTED
// ========================

// Classnames
// https://github.com/JedWatson/classnames

// React-Flip-Toolkit
// https://github.com/aholachek/react-flip-toolkit

// JS Obfuscator (used on command line, not during build. See ber-verify.js)
// https://github.com/javascript-obfuscator/javascript-obfuscator

// React-media (handles responsiveness functions, such to display no-mobile alert)
// https://github.com/ReactTraining/react-media

// react-howler (to handle audio)
// https://github.com/thangngoc89/react-howler

// react-scrollbars-custom
// https://github.com/xobotyi/react-scrollbars-custom