import React from 'react';
import Modal from '../../UI/Modal/Modal';
import './ResetAlert.css';

const ResetAlert = ( props ) => {

	const removeAndReset = () => {
		props.handleRemove();
		props.handleReset();
	}

	return (
		<Modal show={props.show} opaqueBack={false} clickBackdrop={props.handleRemove}>
			<p> Are you sure that you'd like to reset your progress? </p>
			<p> This action cannot be undone. </p>
			<button className="resetModalButton" onClick={removeAndReset} disabled={!props.show}>RESET</button>
			<button className="resetModalButton" onClick={props.handleRemove} disabled={!props.show}>CANCEL</button>
		</Modal>
	);
}

export default ResetAlert;
