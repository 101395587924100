import React from 'react';
import './SettingsMenu.css';
import classNames from 'classnames';

const SettingsMenu = ( props ) => {
	let classes = classNames("consoleButtonMenu", "settingsButtonMenu", props.reveal && "open");

	return (
			<div className={classes} style={{width: "600px"}}>
				<h2> Instructions </h2>
				<ul>
					<li> COLLECT goods by clicking Shift on your keyboard.</li>
					<li> RESEARCH new technology categories with your jam flask.</li>
					<li> DISCOVER new items in a category to get better at collecting.</li>
				</ul>

				<h2> Credits </h2>
				<p> Game design and code by <a href="https://www.richarddub.net">Richard Dub</a> </p>
				<p> Icons provided by <a href="https://fontawesome.com/">Font Awesome</a> </p>
			
				<h2> Save / Load </h2>
				<div className="saveButtonsBox">
					<button onClick={props.save} disabled={!props.open}>
						Save Game
					</button>
					<button onClick={props.load} disabled={!props.open}>
						Load Last Save
					</button>
					<button onClick={props.toggleResetModal} disabled={!props.open}>
						Reset Progress
					</button>
				</div>

				<h2> Feedback </h2>
				<div className="saveButtonsBox">
					<p style={{width: '360px'}}>Help with development!  Suggest words that you think should be included or that should receive a fun message.</p>
					<button disabled={!props.open} onClick={props.toggleCommentModal}>
						Make Suggestion
					</button>
				</div>
			</div>
		);
}

export default SettingsMenu;