import React, { Component } from 'react';
import ResearchMenu from './ResearchMenu/ResearchMenu';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import MusicBox from './MusicBox/MusicBox';
import './ConsoleButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlask, faCog, faGuitar } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';


class ConsoleButton extends Component {

  state = {
    isOpen: false,
    menuRevealed: false,
  }

  timer = "";

  handleMouseOut = () => {
    this.timer = setTimeout(this.closeConsoleButton, 1200);   // reset to 1200
  }

  handleMouseIn = () => {
    clearTimeout(this.timer);
  }

  handleClick = () => {
    if (!this.state.isOpen) {
      this.openConsoleButton();
    }
    else {
        this.closeConsoleButton();
    }
  }

  closeConsoleButton = () => {
    this.setState({ isOpen: false,
                    menuRevealed: false });
  }

  openConsoleButton = () => {
    this.setState({ isOpen: true });
    setTimeout(function(){
      this.setState({ menuRevealed: true });
    }.bind(this), 300);
    this.props.changeTutorial(0);
  }


  render() {
    const buttonType = this.props.menuType;
    let buttonContents = null;
    let icon = "";
    let buttonClasses = classNames("consoleButton", this.state.isOpen && "openConsoleMenu");
    let iconClasses = classNames("consoleIcon", this.state.isOpen && "openConsoleMenu");
    let showConsoleButton = this.props.show ? "showConsoleButton" : null;

    switch (buttonType) {

      case "research":
          icon = faFlask;
          buttonClasses = classNames(buttonClasses, "researchConsoleButton");
          if (this.state.isOpen) {
            buttonContents = <ResearchMenu  reveal={this.state.menuRevealed} 
                                            cats={this.props.categories}
                                            inventory={this.props.inventory}
                                            numUpgrades={this.props.numUpgrades}
                                            increaseCatUpgrades={this.props.increaseCatUpgrades}
                                            closeConsoleButton={this.closeConsoleButton}
                                            removeFromInventory={this.props.removeFromInventory}
                                            updateTitle={this.props.updateTitle}
                                            jambandPurchased={this.props.jambandPurchased}
                                            revealMusicButton={this.props.revealMusicButton}
                                            changeTutorial={this.props.changeTutorial} />;
          }
          break;

      case "settings":
          icon = faCog;
          buttonClasses = classNames(buttonClasses, "settingsConsoleButton", showConsoleButton);
          iconClasses = classNames(iconClasses, "settingsIconAlign");
          if (this.state.isOpen) {
            buttonContents = <SettingsMenu  reveal={this.state.menuRevealed}
                                            toggleResetModal={this.props.toggleResetModal}
                                            showResetModal={this.props.showResetModal}
                                            toggleCommentModal={this.props.toggleCommentModal}
                                            load={this.props.load}
                                            save={this.props.save}
                                            open={this.state.isOpen} />
          }
          break;

      case "music":
          icon = faGuitar;
          buttonClasses = classNames(buttonClasses, "musicConsoleButton", showConsoleButton);
          iconClasses = classNames(iconClasses, "settingsIconAlign guitarIcon");
          buttonContents = <MusicBox  reveal={this.state.menuRevealed}
                                      songsUnlocked={this.props.songsUnlocked}
                                      addSong={this.props.addSong}
                                      entries={this.props.entries}
                                      numUpgrades={this.props.numUpgrades}
                                      open={this.state.isOpen}
                                      addToPlaylist={this.props.addToPlaylist}
                                      removeFromPlaylist={this.props.removeFromPlaylist}
                                      songsOffPlaylist={this.props.songsOffPlaylist}
                                      playing={this.props.musicPlaying}
                                      musicPlayToggle={this.props.musicPlayToggle}
                                      setBushSpeed={this.props.setBushSpeed}
                                      />
          break;

      default:
          console.log("Something wrong with ConsoleButton.js");
    }

    return (
    <div className={buttonClasses} 
          id="researchButton" 
          onMouseLeave={this.handleMouseOut}
          onMouseEnter={this.handleMouseIn} 
           >
      <FontAwesomeIcon  className={iconClasses} 
                        icon={icon}
                        onClick={this.handleClick} />
      {buttonContents}
    </div>
    );
  }
}

export default ConsoleButton;
