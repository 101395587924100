const S = {

	ber: 		[
				[	"Berry nice!",
					"Good job!",
					"Yes!",
					"Good job!",
					"Nice find!",
					"A new berry!",
					"Yum!",
					"Yummy!",
					"Let's make XX preserves!",
					"Let's make XX jam!",
					"Mmm, XX juice",
					"Looks tasty!",
					"I love XX juice",
					"Yes!",
					"Nice work!",
					"✓",
					"Good find!",
					"Mmmm... XX",
					"Looks delicious",
					"Very healthy"],
				[	"Success!",
					"Nice discovery!",
					"Nice find!",
					"Mmm, looks tasty!",
					"I love XX juice",
					"Let's make XX jam!",
					"Tasty!",
					"Wow, good find!",
					"Nice!",
					"Fruitful!",
					"Fruitful!",
					"That XX looks amazing",
					"Good job",
					"✓",
					"Well done!",
					"Nature provides",
					"XX juice tonight!",
					"Let's have baked XX",
					"Let's make XX preserves!",
					"A new fruit!",
					"Yum!",
					"Mmmmmm",
					"Sweet and tasty!",
					"I'm on a sugar high from all this XX!"],
				[	"Success!",
					"Looks tasty!",
					"Yes!",
					"Good job!",
					"Nice find!",
					"Delicious!",
					"Healthy eating!",
					"Put it in a salad!",
					"A new ingredient!",
					"✓",
					"Yum... XX!",
					"Let's make glazed XX!",
					"Let's make roasted XX!",
					"Let's eat this XX raw!",
					"Scrumptious!",
					"You're so cool and smart",
					"Good farming!",
					"Our fields are overflowing with XX",
					"Harvest time!",
					"Agricultural bonanza!",
					"You're a farming genius",
					"You're the best farmer ever",
					"Mmm mmm mmm mmm",
					"That XX looks amazing",
					"Nature provides",
					"Mmmmmmm",
					"Mother Nature loves you",
					"Nice green thumb",
					"Plants love you",
					"You have a way with plants",
					"Scrummy yummy XX",
					"Delectable!",
					"I'll get the salad tongs!",
					"XX power!",
					"Your cornucopia overfloweth!",
					"I want to eat it right now!",
					"I'm glad I'm not allergic to XX",
					"I'm all hopped up on XX!"]
				],
	
	trp: 		[[	"Nice catch!",
					"That'll be good in a stew!",
					"That'll fry up nicely!",
					"Yum!",
					"A new animal!",
					"Can't wait to taste it!",
					"Ooh... looks tasty!",
					"Mmm... meat",
					"Yum, XX stew",
					"XX meat is the best meat",
					"Dibs on the tail!",
					"Pan-fried XX sounds nice",
					"Roast XX tonight!",
					"Good catch!",
					"Nice find!",
					"✓",
					"XX! Yum!"]],

	fsh: 		[[	"Nice catch!",
					"Nice catch!",
					"Way to reel one in!",
					"Looks delicious!",
					"That'll fry up nicely",
					"XX! Yum!",
					"Let's poach that up!",
					"That's a keeper!",
					"Reely good work!",
					"XX soup tonight?",
					"Looks delish",
					"Poached XX tonight?",
					"Good catch!",
					"✓",
					"Nice catch",
					"XX fin soup, anyone?",
					"They're bitin' today"]],
	
	hnt: 		[[	"Good shot!",
					"You're a crackshot!",
					"Nice hunting!",
					"You're a movie buff!",
					"Nice shot!",
					"What skill!",
					"Nice shot, movie buff!",
					"Good aim!",
					"Yes!",
					"Good job!",
					"A good hunt!",
					"✓",
					"Bullseye!",
					"Bullseye, sharpshooter!"]],
	
	dom: 		[[	"Success!",
					"Good work!",
					"Nice animal handling!",
					"You've got a way with animals",
					"Perfect!",
					"✓",
					"Way to go!",
					"Yep",
					"Yup",
					"Yes, that's an animal noise",
					"Onomatopoeia!",
					"XX is fun to say",
					"XX! Good thought!",
					"That'll do",
					"That'll do",
					"E-I-E-I-O",
					"E-I-E-I-O",
					"Everywhere a XX XX"]],
	
	bee: 		[[	"Success!",
					"Aw",
					"I could kiss you",
					"You're so sweet",
					"(blush)",
					"That's a sweet li'l name alright",
					"No, you're the XX!",
					"(blush)",
					"Aren't you sweet?",
					"Yes!",
					"✓",
					"Nauseating but correct",
					"Awwww",
					"Aw, right back atcha"]],
	
	hor: 		[[	"Success!",
					"Yep",
					"Yeppers",
					"Yee-up",
					"Boy howdy",
					"Nice looking XX",
					"Nice riding",
					"Nice duds",
					"Nice duds",
					"Yee-haw!",
					"Yippee-ki-yay!",
					"Giddy-up!",
					"All duded up"]],

	pas:  		[[	"Success!",]],

	fer: 		[[	"Success!",]], 

}

export default S;