import React from 'react';
import './MusicMenuLockedSongRow.css';
import MusicMenuInspirationCostCell from './MusicMenuInspirationCostCell/MusicMenuInspirationCostCell';
import MusicMenuInspirationOwnCell from './MusicMenuInspirationOwnCell/MusicMenuInspirationOwnCell';

import D from '../../../../../constants/DiscoveryBuildConstants';


const MusicMenuLockedSongRow = ( {songid, songDesc, songCost, purchase, entries, numUpgrades} ) => {

	const costcells = [<div />, <div />, <div />];
	const youhavecells = [<div />, <div />, <div />];
	const canAfford = [false, true, true];

	const numDiscsMade = cat => entries[cat].length;
	const discTitle = cat => D[cat].title[(numUpgrades[cat]-1)];

		for (let i=0; i*2<songCost.length; i++) {
			let currcost = songCost[i*2];
			let currcat = songCost[(i*2)+1];
			let currtitle = discTitle(currcat);
			canAfford[i] = numDiscsMade(currcat)>=currcost;
			if (currtitle) {
				costcells[i] = <MusicMenuInspirationCostCell cost={currcost} title={discTitle(currcat)} />;
				youhavecells[i] = <MusicMenuInspirationOwnCell canAfford={canAfford[i]} numDiscsMade={numDiscsMade(currcat)} title={discTitle(currcat)} />;
			} else {
				costcells[i] = <div>unknown inspiration</div>;
				youhavecells[i] = <div className="redtext tinyCellPadding">???</div>;
			}
		}	// this isn't really functional programming. I'm relying on side effects.  Oh well.



	const canPurchase = canAfford[0] && canAfford[1] && canAfford[2];


	return (
		<div className="musicMenuLockedSongRow">
			<div className="musicMenuLockedSongRowTopLine">
				<p className="musicMenuLockedSongRowDescription">
						{songDesc}
				</p>
				<button className="musicPurchaseButton songPurchaseButton" onClick={purchase} disabled={!canPurchase}> {canPurchase ? "Learn Jam" : "Inspiration Required"} </button>
			</div>
			<div className="musicInspirationReqs">
				<div> Discoveries needed: </div>
					{costcells[0]}
					{costcells[1]}
					{costcells[2]}
				<div className="tinyCellPadding"> You've discovered: </div>
					{youhavecells[0]}
					{youhavecells[1]}
					{youhavecells[2]}
			</div>
		</div>
	);

}

export default MusicMenuLockedSongRow;