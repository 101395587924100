import {RealC, DevC, currencyName} from './Constants';
import {RealPrices, DevPrices} from './ResearchPrices';

const devMode = false;				// change this variable to change whether devMode is on or off

let C = RealC;
let P = RealPrices;

if (devMode) {
	C = DevC;
	P = DevPrices;
}

export { C, P, currencyName, devMode };



// for production:
// switch devMode to false
// in the StartGame function in App.js, fix the automatic game loading.
// change ber-verify-smalltemp in DiscoveryBuildConstants
// obfuscate all those 


// on final production version, comment out the arrow key functions in the listener in onMount in App.js