class ResearchPrices { 

		revealPrices = {
			ber2: 10,
			hnt2: 300,
			jamband: 0,
		};

		jamband = {
			cost: [[4, "crop"]],
			text: ["Form a jam band"],
			title: []
		};

		ber = {
			cost: [[], [75, "crop", 150, "meat"], [1000, "labor", 400, "honey"]],
			text: ["", "Upgrade Berry-Picking", "Upgrade Fruit-Picking"],
			title: ["", "Clickanana", "Farmclick"],
			clicker: ["", "Forage, Fish, Trap", "Harvest Crops"],
		};

		trp = {
			cost: [[40, "crop"], [400, "meat"]],
			text: ["Research Trapping", "Upgrade Primitive Trapping"],
			title: ["Trapper Clicker"],
			clicker: ["Trap and Pick"],
		};

		fsh = {
			cost: [[40, "crop"], [400, "crop"]],
			text: ["Research Fishing", "Upgrade Primitive Fishing"],
			title: ["Fishclick"],
			clicker: ["Fish and Pick"],
		};

		hnt = {
			cost: [[300, "crop", 200, "meat"], [1000, "crop", 1000, "meat"]],
			text: ["Research Hunting", "Upgrade Primitive Hunting"],
			title: ["Hunter Clicker Gatherer"],
			clicker: ["Hunt & Gather"],
		};

		dom = {
			cost: [[400, "crop", 2000, "meat"]],
			text: ["Research Domestication"],
			title: ["Click Cluck"],
			clicker: ["Breed Livestock"],
		};

		hor = {
			cost: [[2000, "meat", 200, "labor"]],
			text: ["Research Horseback Riding"],
			title: ["Clicker of the Range"],
			clicker: ["Rope Steers"],
		};

		bee = {
			cost: [[1000, "crop", 300, "labor"]],
			text: ["Research Beekeeping"],
			title: ["Clickabee"],
			clicker: ["Scrape Combs"],
		};

		pas = {
			cost: [[4000, "crop", 500, "honey"]],
			text: ["Research Culinary Arts"],
			title: ["Epicure Clicker"],
			clicker: ["Knead & Brew"],
		};

		//add 16000 meat as a third cost?

}

class DevResearchPrices {  

		revealPrices = {
			ber2: 1,
			hnt2: 1,
			jamband: 1,
		};

		jamband = {
			cost: [[1, "crop"]],
			text: ["Form a jam band"],
			title: [],
		};

		ber = {
			cost: [[], [1, "crop", 1, "meat"], [1, "labor", 1, "honey"]],
			text: ["", "Upgrade Berry-Picking", "Upgrade Fruit-Picking"],
			title: ["", "Clickanana", "Farmclick"],
			clicker: ["", "Forage, Fish, Trap", "Toil"],
		};

		trp = {
			cost: [[1, "crop"], [1, "crop"]],
			text: ["Research Trapping", "Upgrade Primitive Trapping"],
			title: ["Trapper Clicker"],
			clicker: ["Trap and Pick"],
		};

		fsh = {
			cost: [[1, "crop"], [1, "crop"]],
			text: ["Research Fishing", "Upgrade Primitive Fishing"],
			title: ["Fishclick"],
			clicker: ["Fish and Pick"],
		};

		hnt = {
			cost: [[1, "crop", 1, "meat"], [1, "crop", 1, "meat"]],
			text: ["Research Hunting", "Upgrade Primitive Hunting"],
			title: ["Hunter Clicker Gatherer"],
			clicker: ["Hunt & Gather"],
		};

		dom = {
			cost: [[1, "crop", 1, "meat"]],
			text: ["Research Domestication"],
			title: ["Click Cluck"],
			clicker: ["Breed Livestock"],
		};

		hor = {
			cost: [[1, "meat", 1, "labor"]],
			text: ["Research Horseback Riding"],
			title: ["Clicker of the Range"],
			clicker: ["Rope Steers"],
		};

		bee = {
			cost: [[1, "crop", 1, "labor"]],
			text: ["Research Beekeeping"],
			title: ["Clickabee"],
			clicker: ["Scrape Combs"],
		};

		pas = {
			cost: [[1, "crop", 1, "honey"]],
			text: ["Research Culinary Arts"],
			title: ["Epicure Clicker"],
			clicker: ["Knead & Brew"],
		};

}

const RealPrices = new ResearchPrices();
const DevPrices = new DevResearchPrices();

export { RealPrices, DevPrices };

export default DevPrices;