import React from 'react';
import './MusicMenuUnlockedSongRow.css';
import SongAndDescription from '../SongAndDescription/SongAndDescription';

const MusicMenuUnlockedSongRow = ( {songid, songTitle, songDesc, numUpgrades, songNotOnPlaylist, addToPlaylist, removeFromPlaylist, manySongsOnPlaylist, nextSongIfLoaded} ) => {

	const buttonFunction = songNotOnPlaylist ? ()=>{addToPlaylist(songid)} : ()=>{removeFromPlaylist(songid); nextSongIfLoaded(songid)};
	const buttonText = songNotOnPlaylist ? "Add" : "Remove";

	const buttonClasses = songNotOnPlaylist ? "musicPurchaseButton songLoadButton songRemovedFromPlaylist" : "musicPurchaseButton songLoadButton";


	return (
		<div className="musicMenuUnlockedSongRow">
				<SongAndDescription title={songTitle} descrip={songDesc} removed={songNotOnPlaylist ? "songRemovedFromPlaylist" : ""}/>
				{(!manySongsOnPlaylist && !songNotOnPlaylist) ? null : <button className={buttonClasses} onClick={buttonFunction}> {buttonText} </button> }
		</div>
	);

}

export default MusicMenuUnlockedSongRow;