import React from 'react';
import D from '../../../../constants/DiscoveryBuildConstants';
import './DiscText.css';
import { currencyName } from '../../../../constants/Constants';

const DiscText = ( {cat, upgrades, numEntries, meatMult} ) => {

	const upgradeIndex = upgrades-1;
	const earningsType = D[cat].collect;
	const earningsText = D[cat].discoveryText[upgradeIndex];
	const addText = D[cat].additionalText[upgradeIndex];
	const meatPerClick = Math.floor(numEntries*(1+(meatMult*0.25)));

	let currencyType = currencyName(earningsType[0], upgrades, false); 

	switch (cat) {

		case "ber":
			if (numEntries === 1) {							// handle singular form of 'berry'
				currencyType = currencyName("crop", upgrades, true);
			}
			return (
		 		<p id={"berEarningsNode"} className="listDescription">
					Currently earning {numEntries} {currencyType} per click.
					<br />
					{earningsText}
					<br />
					<em>{addText}</em>
				</p>
			);

		case "fsh":
		case "trp":
			return (
		 		<p id={cat+"EarningsNode"} className="listDescription">
					Currently earning {meatPerClick} meat per click.
					<br />
					{earningsText}
					<br />
					<em>{addText}</em>
				</p>
			);

		case "hnt":
			return (
				<p id="hntEarningsNode" className="listDescription">
					All meat gains increased by {numEntries*25} percent.
					<br />
					{earningsText}
					<br />
					<em>{addText}</em>
				</p>
			);

		case "dom":
			return (
				<p id="domEarningsNode" className="listDescription">
					Currently earning {meatPerClick} meat and {numEntries} horsepower of labor per click.
					<br />
					{earningsText}
					<br />
					<em>{addText}</em>
				</p>
			);

		case "hor":
			return (
				<p id="horEarningsNode" className="listDescription">
					Currently earning {numEntries} horsepower of labor per click.
					<br />
					{earningsText}
					<br />
					<em>{addText}</em>
				</p>
			);

		case "bee":
			return (
				<p id="beeEarningsNode" className="listDescription">
					Currently earning {numEntries} honey per click.
					<br />
					{earningsText}
					<br />
					<em>{addText}</em>
				</p>
			);

		case "pas":
			return (
				<p id="pasEarningsNode" className="listDescription">
					Currently earning {numEntries} pastr{(numEntries===1) ? "y" : "ies"} per click.
					<br />
					{earningsText}
					<br />
					<em>{addText}</em>
				</p>
			);

		case "fer":
			return (
				<p id="ferEarningsNode" className="listDescription">
					Currently earning {numEntries} bottle{(numEntries===1) ? null : "s"} of wine per click.
					<br />
					{earningsText}
					<br />
					<em>{addText}</em>
				</p>
			);

		default:
			return (
				<p> Error: Text for Discovery Box "{cat}" not set.</p>
			);

	}
}

export default DiscText;