import React from 'react';
import './Backdrop.css';

const Backdrop = ( props ) => {

	const classes = props.opaque ? "Backdrop opaqueBackdrop" : "Backdrop";
	const display = props.show ? <div className={classes} onClick={props.click}/> : null;


	return display;

}

Backdrop.defaultProps = {
	opaque: false,
	click: null,
}


export default Backdrop;