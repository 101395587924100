import React, {Component} from 'react';
import DiscFail from './DiscFail/DiscFail';
import './DiscInput.css';

class DiscInput extends Component {

	state = {outcome: [],
			currentWord: "",}

	handleInputChange = (event) => {
		this.setState({currentWord: event.target.value});
	}

	handleEnter = (event) => { 
		if (event.key === "Enter" && this.state.currentWord !== "") { 
			this.processWord(this.state.currentWord);
		}
	}

	processWord = (word) => {
		let response = this.props.verify(word, this.props.upgrades);
		if (response[0]) {
			this.addItem(response[1]);
			this.setState({currentWord: ""})
		} else {
			this.handleFailure(response[1]);
		}
	}

	addItem = (entry) => {
		if (!this.props.entries.split(" ,").includes(entry)) {				// check if it's a double
			this.props.addToEntries(this.props.cat, entry);
			this.props.startPulse(entry);
			this.handleSuccess(entry);
		} else {
			this.props.startBadPulse(entry);
		}
	}

	handleSuccess = (entry) => {
		const newArray = [...this.state.outcome];
		newArray.push(<DiscFail		success={true} 
									cat={this.props.cat}
									msg="Yes!"
									selfDestruct={this.handleRemoveFailMsg}
									key={entry}
									word={entry}
									upgrades={this.props.upgrades} />);
		this.setState(oldstate => ({	outcome: newArray,
										currentWord: "",}));
	}

	handleFailure = (msg) => {
		const newArray = [...this.state.outcome];
		newArray.push(<DiscFail 	success={false}
									cat={this.props.cat}
									msg={msg}
									selfDestruct={this.handleRemoveFailMsg}
									key={msg+Math.random()} />);
		this.setState(oldstate=> ({	outcome: newArray,
									currentWord: "",}));
	}

	handleRemoveFailMsg = () => {
		const smallerArray = [...this.state.outcome];
		console.log("called");
		if (smallerArray.length>1) {
			this.setState({outcome: smallerArray.slice(1)});
		} else this.setState({outcome: []});
	}

	render() {
		return (
			<div className="inputComponent">
				<input 
					type="text" 
					id={this.props.cat+"Input"} 
					tabIndex={this.props.index}
					className="discoveryInput" 
					placeholder={this.props.place}
					onChange={(e) => this.handleInputChange(e)}
					value={this.state.currentWord}
					onKeyPress={(e) => this.handleEnter(e)} />
				<div className="discOutcomeBox">
					{this.state.outcome}
				</div>
			</div>
		);
	}
}

export default DiscInput;