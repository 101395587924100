import React, { Component } from 'react';
import { C } from '../../../../constants/DevSwitch';

class TimerBar extends Component {

	state = { width: 0,
            tickLength: 60};

	componentDidMount = () => {
	  let startTime = new Date().getTime();
    let timerLength = C.TIMER_SPEED + (C.TIMER_MULT*this.props.totalTech);
    this.setState(state => ({tickLength: timerLength/20}));
		let timerStep = () => {
			if (this.state.width >= 100) {
				clearInterval(id);
				this.props.timerEnd();
				this.props.collect();
			} else {
				let currentTime = new Date().getTime();
				let newwidth = Math.round((currentTime*100 - startTime*100)/timerLength);
				if (newwidth > 100) {newwidth = 100};
				if (newwidth !== this.state.width) {
					this.setState(state=>({width: newwidth}));
				}
			}
		}
		let id = setInterval(timerStep, timerLength/20);
	}

	render () {
		return (
			<div id="timerBar" style={{width: `${this.state.width}%`, "--one-time-unit": `${this.state.tickLength}ms`}} />
		);
	}

}

export default TimerBar;