import React from 'react';
import Title from "./Title/Title";
import Inventory from "./Inventory/Inventory";
import TimerAndClicker from "./TimerAndClicker/TimerAndClicker";
import './Header.css';


const Header = ( props ) => {

    let headerhide = "hiddenHeader";

    if (props.revealed) {
        headerhide = "";
    }

	return (
		<header className={headerhide}>
			<div id="headerBoxTitle" className="headerBox">
    			<Title 
    				title={props.title} />
            </div>
            <div id="headerBoxTimer" className="headerBox">
    			<TimerAndClicker 
    				collect={props.collect}
    				totalTech={props.totalTech}
                    toggleTimer={props.toggleTimer}
                    timerRunning={props.timerRunning}
                    clickerText={props.clickerText} />
            </div>
            <div id="headerBoxInventory" className="headerBox">
		    	<Inventory 
		    		inventory={props.inventory}
                    berryUpgrades={props.berryUpgrades}
                    invLinesShown={props.invLinesShown} />
	  		</div>
  		</header>
	);
}

export default Header;