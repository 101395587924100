import React from 'react';
import Backdrop from '../Backdrop/Backdrop';
import './Modal.css';


const Modal = ( props ) => {

	return (
		<>
			<Backdrop show={props.show} click={props.clickBackdrop} opaque={props.opaqueBack}/>
			<div className="ModalBox"
					style = {{
								transform: props.show ? "translateY(0)" : "translateY(-100vh)",
								opacity: props.show ? 1 : 0,
								width: props.mobileSize ? "80%" : "50%",
								left: props.mobileSize ? "10%" : "25vw",
								top: props.smallTop ? "13vh" : "25vh",
								zIndex: props.mobileSize ? 502 : 500,
							}}
					>
				{props.children}
			</div>
		</>
	);
}

Modal.defaultProps = {
  mobileSize: false,
  smallTop: false,
}

export default Modal;

