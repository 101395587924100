import React from 'react';
import './TutorialBoxContents.css';
import { Flipped } from 'react-flip-toolkit';
import { C } from '../../../../constants/DevSwitch'

const TutorialBoxContents = ( {status, showButton, start} ) => {

	switch (status) {
		case 1:
			return  (
						<Flipped inverseFlipId="tootoriole" scale>
							<div className="tutorialBoxText">
						      	<h3 className="tutorialTextFadeIn tutorialFadout delay1">Your civilization is starving.</h3>
						      	<h3 className="tutorialTextFadeIn tutorialFadout delay2">You are starving.</h3>
						      	<h3 className="tutorialTextFadeIn tutorialFadout delay3">All hope is lost.</h3>
						      	<h3 className="tutorialTextFadeIn tutorialFadout delay4">But... wait...</h3>
					      		<h3 className="tutorialTextFadeIn tutorialFadout delay5">Is that a blueberry bush?</h3>
					     		<button className={showButton ? "startGameBtn showStartBtn" : "startGameBtn hideStartBtn"} 
					     				onClick={start}
					     				disabled={!showButton}>You're saved!</button>
				    		</div>
				    	</Flipped>
					);
		case 2:
			return ( 	
						<Flipped inverseFlipId="tootoriole" scale>
							<div className="tutorialBoxText">
						      	<h3 className="delay1"> Click the button to pick a blueberry!</h3>
				    		</div>
				    	</Flipped>
					);
		case 3:
			return ( 	
						<Flipped inverseFlipId="tootoriole" scale>
							<div className="tutorialBoxText">
						      	<h3> Picking... </h3>
				    		</div>
				    	</Flipped>
					);
		case 4:
			return ( 	
						<Flipped inverseFlipId="tootoriole" scale>
							<div className="tutorialBoxText">
						      	<h3> Your berry count is displayed here.</h3>
						      	<h3> Keep picking! </h3>
						      	<h3> You can also press Shift on your keyboard</h3>
						      	<h3> to click the Pick Berries button.</h3>
				    		</div>
				    	</Flipped>
					);
		case 5:
			return (
						<Flipped inverseFlipId="tootoriole" scale>
							<div className="tutorialBoxText">
								<h3> Blueberries are delicious,</h3>
						      	<h3> but you should try to discover </h3>
						      	<h3> other types of berry. </h3>
				    		</div>
				    	</Flipped>
					);
		case 6:
			return (
						<Flipped inverseFlipId="tootoriole" scale>
							<div className="tutorialBoxText">
								<h3> Awesome! </h3>
								<h3> Keep picking berries, </h3>
								<h3> and keep discovering new berry types! </h3>
				    		</div>
				    	</Flipped>
				);
		case 7:
			return (
						<Flipped inverseFlipId="tootoriole" scale>
							<div className="tutorialBoxText">
								<h3> Don't forget that you can press Shift </h3>
								<h3> to click the Pick Berries button.</h3>
								<p />
								<h3>(To maximize efficiency, you should press Shift</h3>
								<h3> whenever the progress bar isn't moving.)</h3>
								<p />
								<h3> You'll feel full once you've picked {C.CONSOLE_REVEAL} berries.</h3>
				    		</div>
				    	</Flipped>
				);
		case 8:
			return (
						<Flipped inverseFlipId="tootoriole" scale>
							<div className="tutorialBoxText">
								<h3> Nourished by berry power, your mind races with new ideas! </h3>
								<h3> Tip the jam flask to explore your research opportunities. </h3>
				    		</div>
				    	</Flipped>
				);
		case 9:
			return (
						<Flipped inverseFlipId="tootoriole" scale>
							<div className="tutorialBoxText">
								<h3> Some members of your civilization have formed a jam band! </h3>
								<h3> They'll play songs for you as you brainstorm new technologies. </h3>
								<h3> (They assure you that this is purely optional.) </h3>
								<h3> Click the guitar to jam out!</h3>
				    		</div>
				    	</Flipped>
				);
		default:
			console.log("Error: TutorialBox status is "+status);
			return <div/>;
	}
}

export default TutorialBoxContents;