import React from 'react';
import './CommentSubmissionLine.css';

const CommentSubmissionLine = (props) => {

	return (
		<div>
			<label>Category: <input className="suggField" type="textfield" name={"suggestionCat"+props.num} /></label>
			<label>Word: <input className="suggField" type="textfield" name={"suggestionWord"+props.num} /></label>
		</div>
	);
}

export default CommentSubmissionLine;