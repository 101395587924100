import React from 'react';
import './MusicMenu.css';
import MusicMenuLockedSongRow from './MusicMenuLockedSongRow/MusicMenuLockedSongRow';
import MusicMenuUnlockedSongRow from './MusicMenuUnlockedSongRow/MusicMenuUnlockedSongRow';
import MusicMenuPlayer from './MusicMenuPlayer/MusicMenuPlayer';
import SongAndDescription from './SongAndDescription/SongAndDescription';
import Songs from '../../../../constants/SongInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMusic } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import Scrollbar from 'react-scrollbars-custom';

const MusicMenu = ( {entries, numUpgrades, reveal, songsUnlocked, addToPlaylist, removeFromPlaylist, songsOffPlaylist, songLoaded, playing, playToggle, nextSong, prevSong, purchaseSong} ) => {


	const classes = classNames("consoleButtonMenu", "musicButtonMenu", reveal && "open");
	const allSongs = Object.keys(Songs);
	const songsLocked = allSongs.filter(song => !songsUnlocked.includes(song));
	const playlist = songsUnlocked.filter(song => !songsOffPlaylist.includes(song));
	const songsUnlockedPlaylistFirst = playlist.concat(songsOffPlaylist);
	const musicnotes = classNames("musicNoteIcon", playing && "rockin");
	const rockinspeed = {"--bpm": Songs[songLoaded].bpm, "--rockspeed": Songs[songLoaded].rockspeed};
	const nextSongIfLoaded = (song) => {if (song===songLoaded) {nextSong();}}




	////////////////////////
	/* Writing Components */
	////////////////////////

	const player = (songsUnlocked.length<1) ? 	<div style={{textAlign:"center"}}>
													<p>Your jam band musicians will serenade you!</p>
													<p>They just need some inspiration.</p>
												</div> :  <MusicMenuPlayer 	playToggle={playToggle}
																			playing={playing}
																			song={<SongAndDescription 
																			title={Songs[songLoaded].title} 
																			descrip={Songs[songLoaded].description}/>}
																			fullButtonDisplay={playlist.length>1}
																			nextSong={nextSong}
																			prevSong={prevSong}
																			/>
																	

	const lockedSongRows = songsLocked.map(song => (<MusicMenuLockedSongRow key={song} 
																			songid={song}
																			songDesc={Songs[song].description}
																			songCost={Songs[song].cost}
																			purchase={()=>purchaseSong(song)}
																			entries={entries}
																			numUpgrades={numUpgrades}
																			/>));

	const unlockedSongRows = songsUnlockedPlaylistFirst.map(song => (<MusicMenuUnlockedSongRow 	
																			key={song}
																			songid={song}
																			songTitle={Songs[song].title}
																			songDesc={Songs[song].description}
																			addToPlaylist={addToPlaylist}
																			removeFromPlaylist={removeFromPlaylist}
																			songNotOnPlaylist={songsOffPlaylist.includes(song)}
																			manySongsOnPlaylist={playlist.length>1}
																			nextSongIfLoaded={nextSongIfLoaded}
																			/>));



	return (
			<div className={classes}>
				<Scrollbar noScrollX>
					<h2 style={{marginBottom: "40px"}}> 
						<FontAwesomeIcon style={rockinspeed} className={musicnotes} icon={faMusic}/> 
						Jam Band  
						<FontAwesomeIcon style={rockinspeed} className={musicnotes} icon={faMusic}/> 
					</h2>
					{player}
					{songsUnlocked.length>1 && <h2> Playlist </h2>}
					{songsUnlocked.length>1 && <div className="unlockedSongContainer">{unlockedSongRows}</div>}
					{lockedSongRows.length>0 && <h2 style={{marginTop: "55px"}}> Jams To Learn </h2>}
					{lockedSongRows}
				</Scrollbar>
			</div>
		);
}

export default MusicMenu;