import React, { Component } from 'react';
import Media from 'react-media';
import './styles/App.css';
import { C } from './constants/DevSwitch';
import Header from './components/Header/Header';
import Console from './components/Console/Console';
import Discoveries from './components/Discoveries/Discoveries';
import Tutorial from './components/Tutorial/Tutorial';
import MobileWarning from './components/Alerts/MobileWarning/MobileWarning';
import ResetAlert from './components/Alerts/ResetAlert/ResetAlert';
import CommentSubmission from './components/Alerts/CommentSubmission/CommentSubmission';

import BushImage from './img/bush.png';
import classNames from 'classnames';

class App extends Component {

  state = {
    title: "Clickaberry",
    clickerText: "Pick Berry",

    inventory:  { crop: 0, meat: 0, labor: 0, honey: 0, wine: 0, pastry: 0, gold: 0, },
    invLinesShown: {  crop: false, meat: false, labor: false, honey: false, wine: false, pastry: false, gold: false, },
    entries: {  ber: [], trp: [], fsh: [], hnt: [], dom: [], bee: [], hor: [], fer: [], pas: [],},

    numUpgrades: { },
    headerRevealed: false,
    consoleRevealed: false,
    timerRunning: false,
    tutorialStatus: 0,
    showBush: 0,
    shiftReleased: true,
    showResetModal: false, showCommentModal: false,
    gameWasJustLoaded: false,
    showMusicButton: false, musicPlaying: false, songsUnlocked: [], songsOffPlaylist: [], bushRockSpeed: [98, 480],
    version: 0.05,
  };

  initialState = {
    title: "Clickaberry",
    clickerText: "Pick Berry",

    inventory:  { crop: 0, meat: 0, labor: 0, honey: 0, wine: 0, pastry: 0, gold: 0, },
    invLinesShown: {  crop: false, meat: false, labor: false, honey: false, wine: false, pastry: false, gold: false, },
    entries: {  ber: [], trp: [], fsh: [], hnt: [], dom: [], bee: [], hor: [], fer: [], pas: [],},

    numUpgrades: { },
    headerRevealed: false,
    consoleRevealed: false,
    timerRunning: false,
    tutorialStatus: 0,
    showBush: 0,
    shiftReleased: true,
    showResetModal: false, showCommentModal: false,
    gameWasJustLoaded: false,
    showMusicButton: false, musicPlaying: false, songsUnlocked: [], songsOffPlaylist: [], bushRockSpeed: [98, 480],
    version: 0.05,
  };

  componentDidMount() {
    document.addEventListener("keydown", this.handleShift, false);
    document.addEventListener("keyup", (e)=>
                    {if (e.key === "Shift") {
                        this.setState(state=>({shiftReleased: true}))
                      }}, false);
    this.startGame();
  }

  startGame = () => {
    if (C.DEV_MODE) {
      this.setState(prevstate=>({ headerRevealed: true,
                      showBush: 1,
                      tutorialStatus: 0,
                      consoleRevealed: true,
                      numUpgrades: {"ber": 1, "fer": 1}}));
    } else {
      if (!localStorage.getItem("AppState")) {
        this.setState(prevstate=>({tutorialStatus: 1})) // if there's no saved game in localstorage, start tutorial.
      } else { 
         // this.setState(prevstate=>({tutorialStatus: 1}))
         this.loadGame();                             // erase the above line and uncomment this for production!!!
      }
    }
  }


  //////////////////////////////////
  /* Manipulate Timer Functions   */
  /////////////////////////////////

  handleShift = (e) => {
    if(e.key === "Shift" && this.state.headerRevealed) {
      if (!this.state.timerRunning && this.state.shiftReleased) {
        this.setState(state=>({ timerRunning: true,
                                shiftReleased: false}));
        if (this.state.tutorialStatus) {this.moveTutorialAlong("timerStart");}
      } else if (this.state.shiftReleased) {
        this.setState(state=>({ shiftReleased: false }));
      }
    }
    if (C.DEV_MODE) {
      // can test functions in development below
      if(e.key === "ArrowUp" && this.state.headerRevealed) {
        for (let x=0; x<48; x++) {
          this.addToEntries("ber", "tempentry"+x);
        }
      }
      if(e.key === "ArrowDown" && this.state.headerRevealed) {
          this.increaseCatUpgrades("ber");
      }
      if(e.key === "ArrowLeft" && this.state.headerRevealed) {
        for (let x=0; x<48; x++) {
          this.addToEntries("bee", "tempentry"+x);
        }
      }
    }
  }

  toggleTimer = () => {
    let temp = this.state.timerRunning;
    this.setState(state=>({timerRunning: !temp}));
    if (this.state.tutorialStatus) {this.moveTutorialAlong("timerStart");}
  }


  /////////////////////////////////
  /* Savegame Functions          */
  /////////////////////////////////

  saveGame = () => {
    localStorage.setItem("AppState", JSON.stringify(this.state));
  }

  loadGame = () => {
    this.setState(prevState => ({...JSON.parse(localStorage.getItem("AppState")), gameWasJustLoaded: true, musicPlaying: false}));
  }

  resetGame = () => {               
    this.setState(prevState => ({...this.initialState, tutorialStatus: 1})); 
    localStorage.removeItem("AppState");
  }

  toggleResetModal = () => {
    this.setState( state => ({showResetModal: !state.showResetModal}));
  }

  toggleCommentModal = () => {
    this.setState( state => ({showCommentModal: !state.showCommentModal}));
  }


  /////////////////////////////////
  /* Tutorial Display Functions  */
  /////////////////////////////////

  changeTutorialStatus = ( num ) => {
    this.setState( state => ({tutorialStatus: num}))
  }

  moveTutorialAlong = (whereCalledFrom) => {
      switch (whereCalledFrom) {
        case "timerStart":
          if (this.state.tutorialStatus === 2) {
            this.changeTutorialStatus(3);
          }
          if (!this.state.numUpgrades.ber && this.state.inventory.crop>0) {
            this.increaseCatUpgrades("ber");
            setTimeout(()=>{if (this.state.tutorialStatus===4) {this.changeTutorialStatus(5)}}, 1200);
          }
          break;
        case "collectGoods":
          if (!this.state.consoleRevealed && this.state.inventory.crop>=C.CONSOLE_REVEAL) {
           this.showConsole();
           this.changeTutorialStatus(8);
          }
          if (this.state.tutorialStatus===3) {
            this.changeTutorialStatus(4);
          }
          if ((this.state.tutorialStatus===6 || this.state.tutorialStatus===5) && this.state.inventory.crop>=11) {
            this.changeTutorialStatus(7);
          }
          break;
        case "addToDiscoveryList":
          if (this.state.tutorialStatus===6 && this.state.entries.ber.length===4) {
            this.changeTutorialStatus(7);
          } else if (this.state.entries.ber.length === 2 && Object.keys(this.state.numUpgrades).length === 1 && !this.state.consoleRevealed) {
            this.setState(state=>({ clickerText: "Pick Berries",
                                    tutorialStatus: 6 }));
          }
          break;
        default:
          console.log("Error: moveTutorialAlong called without parameter");
      }
  }

  showHeader = () => {
    this.setState( state => ({headerRevealed: true}));
  }

  showConsole = () => {
    this.setState( state => ({consoleRevealed: true}));
  }

  showBush = () => {
    this.setState( state => ({showBush: true}) )
  }

  /////////////////////////////////////
  /* Inventory change functions      */                        
  /////////////////////////////////////

  cropsPerClick = () => {
    return this.state.entries.ber.length || 1;
  }

  meatPerClick = () => {
    let fish = this.state.entries.fsh.length || 0;
    let trap = this.state.entries.trp.length || 0;
    let hunt = this.state.entries.hnt.length || 0;
    let domest = this.state.entries.dom.length || 0;
    let meatFromFish = Math.floor(fish*(1+(hunt*0.25)));
    let meatFromTrap = Math.floor(trap*(1+(hunt*0.25)));
    let meatFromDomest = Math.floor(domest*(1+(hunt*0.25)));
    return (meatFromFish + meatFromTrap + meatFromDomest);
  }

  laborPerClick = () => {
    let ranch = this.state.entries.hor.length || 0;
    let domest = this.state.entries.dom.length || 0;
    return (ranch + domest);
  }

  honeyPerClick = () => (this.state.entries.bee.length || 0);

  winePerClick = () => (this.state.entries.fer.length || 0);

  pastriesPerClick = () => (this.state.entries.pas.length || 0);

  // increase inventory numbers (after timer bar is finished)
  collectGoods = () => {
    let tempInventory = {...this.state.inventory};
    let tempInvLinesShown = {...this.state.invLinesShown};
    tempInventory.crop += this.cropsPerClick();
    tempInventory.meat += this.meatPerClick();
    tempInventory.labor += this.laborPerClick();
    tempInventory.honey += this.honeyPerClick();
    tempInventory.wine += this.winePerClick();
    tempInventory.pastry += this.pastriesPerClick();
    if (tempInventory.crop && !tempInvLinesShown.crop) {tempInvLinesShown.crop = true};
    if (tempInventory.meat && !tempInvLinesShown.meat) {tempInvLinesShown.meat = true};
    if (tempInventory.labor && !tempInvLinesShown.labor) {tempInvLinesShown.labor = true};
    if (tempInventory.honey && !tempInvLinesShown.honey) {tempInvLinesShown.honey = true};
    if (tempInventory.wine && !tempInvLinesShown.wine) {tempInvLinesShown.wine = true};
    if (tempInventory.pastry && !tempInvLinesShown.pastry) {tempInvLinesShown.pastry = true};
    this.setState(state => ({ inventory: tempInventory,
                              invLinesShown: tempInvLinesShown}));
    if (this.state.tutorialStatus) {this.moveTutorialAlong("collectGoods");}
    this.saveGame();
  }

  // subtract items from inventory (after making purchases)
  removeFromInventory(curr, num, curr2, num2) {
    let invCopy = {...this.state.inventory};
    invCopy[curr] = invCopy[curr]-num;
    if (arguments.length>2) {
      invCopy[curr2] = invCopy[curr2]-num2;
    }
    this.setState({inventory: invCopy});
  }

  //////////////////////////////////////////
  /*     Adding to Discovery Lists        */
  /////////////////////////////////////////


  addToEntries = (cat, entry) => {
    let entriesCopy = {...this.state.entries};
    entriesCopy[cat].push(entry);
    this.setState(state=>({entries: entriesCopy}));
    if (this.state.tutorialStatus) {this.moveTutorialAlong("addToDiscoveryList");}
    this.saveGame();
  }


  //////////////////////////////////////////
  /*   Adding Categories and Upgrades     */
  /////////////////////////////////////////


  // change title (on header and browser bar) and text on clicker
  updateTitle = (newtitle, newclicker) => {
    let title = newtitle;
    let clicker = newclicker;
    if (Object.keys(this.state.numUpgrades).length === 2) {
      title = "Survivalist Clicker";
      clicker = "Pick, Trap, Fish";
    }
    this.setState({title: title,
                    clickerText: clicker});
  }

  increaseCatUpgrades = (cat, ...extra) => {
    let numUpgradesCopy = {...this.state.numUpgrades};
    if (numUpgradesCopy[cat]) {
      numUpgradesCopy[cat] = numUpgradesCopy[cat] + 1;
    } else {
      numUpgradesCopy[cat] = 1;
    }
    if (extra.length) {
      numUpgradesCopy[extra[0]] = 1;
    }
    this.setState(state=>({gameWasJustLoaded: false,
                            numUpgrades: numUpgradesCopy}));
  }


  //////////////////////////////////////////
  /*         Music Controls               */
  /////////////////////////////////////////

  addSong = (song) => {
    let songArrayCopy = [...this.state.songsUnlocked];
    if (!songArrayCopy.includes(song)) {
      songArrayCopy.push(song);
    }
    this.setState(state=>({songsUnlocked: songArrayCopy}));
  }

  removeFromPlaylist = (song) => {
    let songsOffCopy = [...this.state.songsOffPlaylist];
    if (!songsOffCopy.includes(song)) {
      songsOffCopy.push(song);
    }
    if (songsOffCopy.length < this.state.songsUnlocked.length) {
      this.setState(state=>({songsOffPlaylist: songsOffCopy}));
    }
  }

  addToPlaylist = (song) => {
    let songsOffCopy = [...this.state.songsOffPlaylist].filter(x => x!==song);
    this.setState(state=> ({songsOffPlaylist: songsOffCopy}));
  }

  revealMusicButton = () => {
    this.setState(prevstate=>({showMusicButton: true}));
  }

  musicPlayToggle = () => {
    this.setState(prevstate => ({musicPlaying: !prevstate.musicPlaying}));
  }

  setBushRockSpeed = (bpm, rockspeed) => {
    this.setState(prevstate => ({bushRockSpeed: [bpm, rockspeed]}));
  }


  render() {
    document.title = this.state.title;
    const unlocked = Object.keys(this.state.numUpgrades);  // turns the Object that lists num of upgrades into an array of the unlocked category names
    const bushclasses = classNames("bush", this.state.showBush || "bushhidden", this.state.musicPlaying && "bushdance");

    return (
      <div className="App">
        <ResetAlert show={this.state.showResetModal}
                    handleRemove={this.toggleResetModal}
                    handleReset={this.resetGame} />
        <CommentSubmission 
                    show={this.state.showCommentModal}
                    handleRemove={this.toggleCommentModal}/>
       <Header 
          title={this.state.title} 
          totalTech={unlocked.length}
          inventory={this.state.inventory}
          collect={this.collectGoods}
          toggleTimer={this.toggleTimer}
          timerRunning={this.state.timerRunning}
          clickerText={this.state.clickerText} 
          revealed={this.state.headerRevealed}
          berryUpgrades={this.state.numUpgrades.ber || 0}
          invLinesShown={this.state.invLinesShown} />
       <Discoveries
          unlocks={unlocked.join(" ")}
          entries={this.state.entries}
          addToEntries={this.addToEntries}
          upgrades={this.state.numUpgrades}
          meatMult={this.state.entries.hnt.length || 0}
          justLoaded={this.state.gameWasJustLoaded} />
       <Console 
          consoleRevealed={this.state.consoleRevealed} 
          categories={unlocked}
          inventory={this.state.inventory}
          numUpgrades={this.state.numUpgrades}
          increaseCatUpgrades={this.increaseCatUpgrades}
          removeFromInventory={this.removeFromInventory.bind(this)}
          updateTitle={this.updateTitle}
          changeTutorial={this.changeTutorialStatus} 
          toggleResetModal={this.toggleResetModal}
          showResetModal={this.state.showResetModal}
          toggleCommentModal={this.toggleCommentModal}
          load={this.loadGame}
          save={this.saveGame}
          entries={this.state.entries}
          songsUnlocked={this.state.songsUnlocked}
          addSong={this.addSong}
          addToPlaylist={this.addToPlaylist}
          removeFromPlaylist={this.removeFromPlaylist}
          songsOffPlaylist={this.state.songsOffPlaylist}
          musicPlaying={this.state.musicPlaying}
          musicPlayToggle={this.musicPlayToggle}
          showMusicButton={this.state.showMusicButton}
          revealMusicButton={this.revealMusicButton}
          setBushSpeed={this.setBushRockSpeed}
          showSettingsButton={this.state.numUpgrades.hasOwnProperty('trp') 
                              || this.state.numUpgrades.hasOwnProperty('fsh')} />
        {this.state.tutorialStatus ?
          <Tutorial status={this.state.tutorialStatus}
                    showHeader={this.showHeader}
                    showBush={this.showBush}
                    changeStatus={this.changeTutorialStatus} /> : null}
        <img src={BushImage} alt='bush' className={bushclasses} style={{"--bpm": this.state.bushRockSpeed[0], "--rockspeed": this.state.bushRockSpeed[1]*2}}/>
       <footer />
        
        <Media query="(max-width: 1279px)"> 
          {matches => matches 
            ? 
            <Media query="(max-width: 1023px)">
              {match => match ? <MobileWarning mobile={true} /> : <MobileWarning />}
            </Media> 
            : 
            <Media query="(max-height: 680px)">
              {match => match ? <MobileWarning /> : null}
            </Media>}
        </Media>
        
      </div>
    );
  }
}

export default App;