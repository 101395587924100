class Constants {  

		DEV_MODE		= false;

		TIMER_SPEED 	= 3500; 	// milliseconds for timer at start
		TIMER_MULT 		= 1500;		// addl ms for each technology purchased

		CONSOLE_REVEAL	= 20;

		DISCOVERYBOX_DOUBLEWIDTH = 49;

}

class DevConstants {  

		DEV_MODE		= true;

		TIMER_SPEED 	= 1000; 	// milliseconds for timer at start
		TIMER_MULT 		= 0;		// addl ms for each technology purchased

		CONSOLE_REVEAL	= 0;
		
		DISCOVERYBOX_DOUBLEWIDTH = 49;
		
}


/***********************************************/

function currencyName(curr, upgradesArg = 1, singular = false) {
	let word = curr;
	let upgrades = upgradesArg;
	if (upgradesArg) {
		upgrades--;
	}
	switch (curr) {
		case "crop":
			if (singular) {
				word = ["berry","fruit","crop"][upgrades];
			} else {
				word = ["berries","fruit","crops"][upgrades];
			}
			break;
		case "labor":
			word = "horsepower";
			break;
		case "pastry":
			if (!singular) {
				word = "pastries";
			}
			break;
		default: 
			word = curr;
			break;
	}
	return word;
}

const RealC = new Constants();
const DevC = new DevConstants();

export { currencyName, RealC, DevC };

export default DevC;