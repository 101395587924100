// import berVerify from '../components/Discoveries/Discovery/verify-scripts/ber-verify-reroute';
// import trpVerify from '../components/Discoveries/Discovery/verify-scripts/trp-verify';
// import fshVerify from '../components/Discoveries/Discovery/verify-scripts/fsh-verify';
// import hntVerify from '../components/Discoveries/Discovery/verify-scripts/hnt-verify';
// import domVerify from '../components/Discoveries/Discovery/verify-scripts/dom-verify';
// import horVerify from '../components/Discoveries/Discovery/verify-scripts/hor-verify';
// import beeVerify from '../components/Discoveries/Discovery/verify-scripts/bee-verify';
// import ferVerify from '../components/Discoveries/Discovery/verify-scripts/fer-verify';
// import pasVerify from '../components/Discoveries/Discovery/verify-scripts/pas-verify';


import berVerify from '../components/Discoveries/Discovery/verify-scripts/ber-verify';
import trpVerify from '../components/Discoveries/Discovery/verify-scripts/trp-verify-obfuscated';
import fshVerify from '../components/Discoveries/Discovery/verify-scripts/fsh-verify-obfuscated';
import hntVerify from '../components/Discoveries/Discovery/verify-scripts/hnt-verify-obfuscated';
import domVerify from '../components/Discoveries/Discovery/verify-scripts/dom-verify-obfuscated';
import horVerify from '../components/Discoveries/Discovery/verify-scripts/hor-verify-obfuscated';
import beeVerify from '../components/Discoveries/Discovery/verify-scripts/bee-verify-obfuscated';
import ferVerify from '../components/Discoveries/Discovery/verify-scripts/fer-verify-obfuscated';
import pasVerify from '../components/Discoveries/Discovery/verify-scripts/pas-verify-obfuscated';

// on production, run javascript-obfuscator (see commands in ber-verify.js file)
// eg import berVerify from './verify-scripts/ber-verify-obfuscated';
// also consider doing this to this very file to hide future upgrades.
// make sure import statement in ber-verify is at front of file.
// Note: this isn't currently working with Create React App.  See the To Do notes for more information.
// don't forget vegVerify in berVerify

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faSquirrel, faFish, faPig, faHorse, faLemon, faWheat, faWineGlassAlt, faCroissant } from '@fortawesome/pro-solid-svg-icons';
import { faCrosshairs, faHexagon } from '@fortawesome/pro-regular-svg-icons';

const DiscoveryBuildConstants = {

	ber: {
		title: ["Berry-Picking","Fruit-Picking","Farming"],
		firstItem: "blueberry",
		placeholderText: ["enter berry","enter fruit","enter food crop"],
		collect: ["crop"],
		discoveryText: ["To increase rate, discover new types of berry:","To increase rate, discover new types of fruit:","To increase rate, discover plants that are grown for human consumption:"],
		additionalText: ["","",""],
		verify: berVerify,
		icon: [<FontAwesomeIcon icon={faCircle} />, <FontAwesomeIcon icon={faLemon} />,  <FontAwesomeIcon icon={faWheat} />],
	},	

	fsh: {
		title: ["Primitive Fishing","Fishing"],
		firstItem: "trout",
		placeholderText: ["enter small fish","enter fish"],
		collect: ["meat"],
		discoveryText: ["To increase rate, discover new small fish:","To increase rate, discover new fish of unintimidating size:"],
		additionalText: ['("small" means five letters or fewer)','("unintimidating size" means seven letters or fewer)'],
		verify: fshVerify,
		icon: [<FontAwesomeIcon icon={faFish} />],
	},

	trp: {
		title: ["Primitive Trapping","Trapping"],
		firstItem: "squirrel",
		placeholderText: ["enter small mammal","enter mammal or lizard"],
		collect: ["meat"],
		discoveryText: ["To increase rate, discover new small mammals:","To increase rate, discover new small mammals or lizards:"],
		additionalText: ['("small" means smaller than a toaster)','("small" means smaller than a toaster)'],
		verify: trpVerify,
		icon: [<FontAwesomeIcon icon={faSquirrel} />],
	},

	hnt: {
		title: ["Primitive Hunting", "Hunting"],
		firstItem: "The Hunted",
		placeholderText: ['enter movie containing "hunt"', 'enter film with "hunt" or "game"'],
		collect: ["meat"],
		discoveryText: ['To increase multiplier, discover movies with "hunt" in the title:', 'To increase multiplier, discover movies with "hunt" or "game" in the title:'],
		additionalText: ["",""],
		verify: hntVerify,
		icon: [<FontAwesomeIcon icon={faCrosshairs} />],
	},

	dom: {
		title: ["Animal Husbandry"],
		firstItem: "moo",
		placeholderText: ["enter barnyard sound"],
		collect: ["meat", "labor"],
		discoveryText: ["To increase rate, discover animal sounds heard on a barnyard:"],
		additionalText: [""],
		verify: domVerify,
		icon: [<FontAwesomeIcon icon={faPig} />],
	},

	hor: {
		title: ["Ranching"],
		firstItem: "Stetson hat",
		placeholderText: ["enter cowpoke clothing"],
		collect: ["labor"],
		discoveryText: ["To increase rate, discover articles of cowboy wear:"],
		additionalText: [""],
		verify: horVerify,
		icon: [<FontAwesomeIcon icon={faHorse} />],
	},

	bee: {
		title: ["Beekeeping"],
		firstItem: "honey",
		placeholderText: ["enter sweet little pet name"],
		collect: ["meat", "labor"],
		discoveryText: ["To increase rate, discover romantic terms of endearment:"],
		additionalText: [""],
		verify: beeVerify,
		icon: [<FontAwesomeIcon icon={faHexagon} />],
	},

	pas: {
		title: ["Pastry-Making"],
		firstItem: "croissant",
		placeholderText: ["enter pastry"],
		collect: ["pastry"],
		discoveryText: ["To increase rate, discover new pastries:"],
		additionalText: [""],
		verify: pasVerify,
		icon: [<FontAwesomeIcon icon={faCroissant} />],
	},

	fer: {
		title: ["Fermentation"],
		firstItem: "Red Red Wine",
		placeholderText: ["enter boozy tune"],
		collect: ["wine"],
		discoveryText: ["To increase rate, discover song titles containing an alcoholic drink:"],
		additionalText: [""],
		verify: ferVerify,
		icon: [<FontAwesomeIcon icon={faWineGlassAlt} />],
	},

};

export default DiscoveryBuildConstants;
