import React from 'react';

const MusicMenuInspirationOwnCell = ({canAfford, title, numDiscsMade}) => {
	return (
			<div className="tinyCellPadding musicCellLowercase">
				<span className={canAfford ? "greentext" : "redtext"}>{numDiscsMade}</span> {title}
			</div>
		);
}

export default MusicMenuInspirationOwnCell;