import React, {Component} from 'react';
import './CommentSubmission.css';
import Modal from '../../UI/Modal/Modal';
import CommentSubmissionLine from './CommentSubmissionLine/CommentSubmissionLine'

class CommentSubmission extends Component {

	state = {
		numLines: 3,
	}

	//let tumblerina = 
	//	<span>Category: </span> <input type="text" name="cat"><br>

	addLine = () => {
		this.setState(prevstate => ({numLines: prevstate.numLines+1}));
	}

	resetLines = () => {
		this.setState(prevstate => ({numLines: 3}));
		this.props.handleRemove();
	}


	render () {

		let commentLines = [];
		let lineNum = 0;
		while (lineNum<this.state.numLines) {
			commentLines.push(<CommentSubmissionLine num={lineNum} key={"Submiss"+lineNum}/>);
			lineNum++;
		}

		return (
			<Modal show={this.props.show} opaqueBack={false} clickBackdrop={this.props.handleRemove} smallTop={true}>
				<div id="feedbackScrollBox">
					<h2 style={{paddingTop: "-8px"}}>Make Suggestions</h2>
					<form id="contactform" action="//formspree.io/clickaberry@gmail.com" method="POST" target="_blank" onSubmit={this.resetLines}>
						<input type="hidden" name="_subject" value="Website contact" />
						<input type="text" name="_gotcha" style={{display: "none"}} />
						{commentLines}
						<div style={{fontSize: "0.8em", marginTop: "20px"}}>If you'd like space for more words, <span onClick={this.addLine} style={{cursor: "pointer", padding: "2px 5px", background: "var(--deep-purple)", color: "white", borderRadius: "4px"}}>click here</span><br/> </div>
						Other comments: <br style={{marginTop: "15px"}}/>
						<textarea className="suggBigField" cols="52" rows="5" name="miscSuggestion" /><br/>
						<label style={{marginBottom: "10px"}}>Your name: <input className="suggField" type="textfield" name="authorName"/></label><br/>
						<input style={{marginBottom: "20px"}} className="feedbackSubmitBtn" type="submit" value="Submit" />
					</form>
					<div style={{fontSize: "0.8em"}}>On submitting, please prove you're not a robot on the browser tab that opens. <br/>
					You can then close that tab and return to this tab to keep playing.</div>
				</div>
			</Modal>
		);
	}
}

export default CommentSubmission;

// uses Formspree.io to send forms