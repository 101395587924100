import React from 'react';
import TimerBar from './TimerBar';
import './Timer.css';

const Timer = ( props ) => {

	let revealTimer = <div id="timerBar" style={{width: 0}} />;

	if (props.timerRunning) {
		revealTimer = <TimerBar 
			totalTech={props.totalTech} 
			timerEnd={props.timerEnd}
			collect={props.collect}
			/>
	}

	return (
		<div id="timerBox">
  			{revealTimer}
   		</div>
	);
}

export default Timer;

