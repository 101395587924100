import React from 'react';
import Timer from "./Timer/Timer";
import Clicker from "./Clicker/Clicker";
import './TimerAndClicker.css';

const TimerAndClicker = ( props ) => {
		return (
			<div className="timerAndClicker">
				<Clicker 
					timerStart={props.toggleTimer}
                    timerRunning={props.timerRunning}
                    clickerText={props.clickerText} />
				<Timer 
					timerRunning={props.timerRunning}
					totalTech={props.totalTech}
					timerEnd={props.toggleTimer}
					collect={props.collect}/>
			</div>
		);
}

export default TimerAndClicker