import React from 'react';

const MusicMenuInspirationCostCell = ({cost, title}) => {

	return (
				<div className="musicCellLowercase">
					{cost} {title}
				</div>
		);

}

export default MusicMenuInspirationCostCell;