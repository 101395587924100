import React from 'react';
import './SongAndDescription.css';


const SongAndDescription = ( {title, descrip, removed} ) => {

	return (
			<div className={removed}>
				<p style={{margin: "22px 0"}}>
					{title}
				</p>
				<div className="songDescription">
					{descrip}
				</div>
			</div>
			);
}

export default SongAndDescription;

