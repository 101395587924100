import React, { Component } from 'react';
import './TutorialBox.css';
import { Flipped } from 'react-flip-toolkit';
import TutorialBoxContents from './TutorialBoxContents/TutorialBoxContents';
import classNames from 'classnames';

class TutorialBox extends Component {

	state = {
		showIntroText: false,
		showButton: false,
		makeFirstBoxSmall: false,
	}

	componentDidMount() {
		if (this.props.status===1) {
			setTimeout(this.toggleIntroText, 1300);
			setTimeout(this.props.showBush, 3500);
			setTimeout(this.showButton, 9500); 
		}
	}

	toggleIntroText = () => {
		this.setState(prevstate=>({showIntroText: !prevstate.showIntroText}));
	}

	showButton = () => {
		this.setState( {showButton: true} )
	}

	start = () => {
		this.props.showHeader();
		this.toggleIntroText();
		this.setState(prevstate=>({makeFirstBoxSmall: true}));
		setTimeout(this.props.changeStatus, 700, 2);
	}

	render() {
		const classnames = classNames(	"tutorialBox", 
										"tutorialBoxStage"+this.props.status, 
										(this.props.status===1||this.props.status===9) ? "zoomIn" : null, 
										(this.state.showIntroText || this.props.status!==1) ? null : "hiddenTutorialIntroText",
										(this.state.makeFirstBoxSmall && this.props.status===1) ? "smallFirstTutorialBox" : null,
									);
		return (
			<Flipped flipId="tootoriole">
				<div className={classnames}>
					<div className="noOverflow">
						<TutorialBoxContents 	status={this.props.status}
												showButton={this.state.showButton}
												start={this.start} />
					</div>
				</div>
			</Flipped>
		);
	}
}

export default TutorialBox;

// this could really be refactored.  I'm not sure it needs to be a class component at all.