import React from 'react';
import './DiscFail.css';
import S from '../../../../../constants/SuccessMessages';

const DiscFail = ( {success, msg, cat, selfDestruct, word, upgrades} ) => {

	const capitalize = string => string.charAt(0).toUpperCase()+string.slice(1);

	let successMsg = "";

	if (success) {
		const succFinalIndex = S[cat].length-1;
		const succIndex = Math.min(succFinalIndex, upgrades);
		const randomIndex = Math.floor(Math.random() * S[cat][0].length);

		successMsg = S[cat][succIndex][randomIndex];
		successMsg = successMsg.replace(/^XX/, capitalize(word));
		successMsg = successMsg.replace(/XX/, word);
		successMsg = successMsg.replace(/XX/, word);
	}

	return (
		<div 
			id={cat+"Outcome"} 
			className={success ? "discoverySuccess" : "discoveryFail"}
			onAnimationEnd={selfDestruct}>
			{success ? successMsg : msg} 
		</div>
	);
}

export default DiscFail;
