import React from 'react';
import InventoryItem from './InventoryItem/InventoryItem';
import './Inventory.css';
import { currencyName } from '../../../constants/DevSwitch';

const Inventory = (props) => {

	const capitalize = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const invLinesArray = currencies  =>
			currencies.map( curr => {
				let currCount = props.inventory[curr];
				if (!props.invLinesShown[curr]) {return null};		
					// don't make a line for what's never needed to be shown
				let currDisplayName = capitalize(currencyName(curr));
				if (curr === "crop") {
					currDisplayName = capitalize(currencyName(curr, props.berryUpgrades));
				}
				return (
					<InventoryItem 	key={curr} 
									curr={curr} 
									currName={currDisplayName} 
									currCount={currCount} />
						);
					}
			);

	let currencies = Object.keys(props.inventory);
	let lines = invLinesArray(currencies);

	return (
			<div id="inventoryBox">
				{lines}
			</div>
		);


}

export default Inventory;