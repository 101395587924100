import vegVerify from './veg-verify-obfuscated';

// run this code to obfuscate all verify js:

// cd C:\\Users\Richard\Dropbox\My Work\My Projects\web-projects\blueberry-clicker-react\src\components\Discoveries\Discovery\verify-scripts
// javascript-obfuscator ./ --compact true --unicode-escape-sequence true
// or
// // javascript-obfuscator ./ --compact true --string-array-encoding base64
// then search the obfuscated berry verify file for an improt statement and move it to the front.


function berVerify(str, update) {

  // replace == with ===

  // addList ?\((["a-z0-9]*), ?(["a-zA-Z0-9 \-'üû:\.!,\(\)]*)\)
  // replace with
  // return [true, $2]

  // returnFailMsg ?\((["a-z0-9]*), ?(["a-zA-Z0-9 \-'ü!]*)\)
  // replace with
  // return [false, $2]

  // isinArray\(str, ?(\["[a-zA-Z0-9", ]*\])\)
  // replace with
  // $1.includes\(str\)

  // add endsIn and startsWith functions (see trp-verify) if needed

  // fix sanitize, get rid of if str=="" bit
  // add export to end



  str = str.toLowerCase();
  str = str.trim();
  str = str.replace(/[_\W0-9]/g, "");

  if (update===0) {
    if (["guava", "pineapple", "coffee", "apple", "star", "watermelon", "orange", "lime", "snake","squash","teaberry"].includes(str)) {
      str = str+"berry";
    }
  }

  if (update<2) {
    if (str==="pea") {
      str = str+"berry";
    }
  }

  if (["guavaberry", "peaberry", "peaberries", "guavaberries", "pineappleberry", "pineappleberries", "coffeeberry", "coffeeberries", "appleberry", "appleberries", "starberry", "starberries", "watermelonberry", "watermelonberries", "orangeberry", "orangeberries", "limeberry", "limeberries", "snakeberry", "snakeberries","riceberry","riceberries"].includes(str)) {
    str = str+"berry";
  }

  if (str.substring(str.length - 7) === "berries") {
    str = str.substring(0, str.length - 7);
  }
  if (str.substring(str.length - 5) === "berry") {
    str = str.substring(0, str.length - 5);
  }
  if (str.substring(str.length - 8) === "cherries") {
    str = str.substring(0, str.length - 3)+"y";
  }
  if (str.substring(str.length - 1) === "s") {
    str = str.substring(0, str.length - 1);
  }
  if (str.substring(0, 8) === "american") {
    str = str.substring(8);
  }
  if (str.substring(0, 6) === "common") {
    str = str.substring(6);
  }
  if (str.substring(str.length - 5) === "fruit" && str!=="grapefruit" && str!=="jackfruit" && str!=="garlicfruit" && str!=="eggfruit" && str!=="hibiscusfruit") {
    str = str.substring(0, str.length - 5);
  }

  let length = str.length; 


  // here begins the huge number of possible inputs

    if (["goji", "wolf", "chinesewolf","chineseteaplant","chinesedesertthorn","redmedlar", "himilayangoji","chineseboxthorn","tibetangoji","mede","barbarymatrimonyvine","matrimonyvine","murali","dretsherma","ghostthorn"].includes(str) || /dukeofargyll/.test(str)) {
      return [true, "goji berry"];
    } else if (["rasp", "razz", "hind", "nepaleserasp"].includes(str)) {
      return [true, "raspberry"];
    } else if (["blue", "blu", "blueberry", "highbushblue", "swamphuckle", "swampblue", "tallhuckle", "highblue"].includes(str)) {
      return [true, "blueberry"]; 
    } else if (["lingon", "lingin", "lingen", "ikea", "lincoln", "cow", "partridge", "fox", "lowbushcran", "mountainbil", "red", "redwhortle", "quail", "beaver", "cougar", "mountaincran"].includes(str)) {
      return [true, "lingonberry"];
    } else if (["boysen", "boisen", "boison", "boizen", "boyson"].includes(str)) {
      return [true, "boysenberry"];
    } else if (["black", "bramble", "cane", "tupy", "tupi", "sandblack", "scald"].includes(str)) {
      return [true, "blackberry"];
    } else if (["straw", "fraisesdesboi", "fraisesdeboi", "fraisesduboi", "wildstraw", "alpinestraw", "beachstraw", "marshallstraw", "siletz"].includes(str)) {
      return [true, "strawberry"];
    } else if (["cran", "cranberrysauce", "fen", "bounce", "crane"].includes(str)) {
      return [true, "cranberry"];
    } else if (["saskatoon", "canadian", "sask", "june", "service", "sarvi", "shadwood", "shadbush", "sugarplum", "wildplum", "pigeon", "shad"].includes(str)) {
      return [true, "saskatoon"];
    } else if (["acai", "aa", "aai", "aca", "superfood", "assai"].includes(str)) {
      return [true, "açaí berry"];
    } else if (["emblic","emblicmyrobalan","myrobalan","indiangoose","malacca","malaccatree","amla","amalaki","amalaka","emblica"].includes(str)) {
      return [true, "emblic"]
    } else if (["bar", "berberi", "goldenbar", "zereshk"].includes(str)) {
      return [true, "barberry"];
    } else if (["bear", "alpinebear", "redbear", "bearsgrape", "uvaursi", "kinnikinnick", "meal", "rock", "sand"].includes(str)) {
      return [true, "bearberry"];
    } else if (["bil", "bill", "blae", "whortle", "wim", "whim", "win", "whin", "jagody", "mlyn", "melyne", "huckle", "hurtle", "blackhuckle", "gardenhuckle"].includes(str)) {
      return [true, "huckleberry"];
    } else if (["bogbil", "bogblue", "northernbil", "westernblue"].includes(str)) {
      return [true, "bog bilberry"];
    } else if (["thimble", "blackrasp", "wildblackrasp", "blackcap", "scotchcap", "blackcaprasp", "munger", "nativerasp"].includes(str)) {
      return [true, "thimbleberry"];
    } else if (["koreanblack", "bokbunja"].includes(str)) {
      return [true, "bokbunja"];
    } else if (["purplerasp", "brandywine", "royalty", "estate"].includes(str)) {
      return [true, "purple raspberry"];
    } else if (["mysorerasp", "ceylonrasp", "hellrasp"].includes(str)) {
      return [true, "Mysore raspberry"];
    } else if (str === "whitebarkrasp") {
      return [true, "white bark raspberry"];
    } else if (["whiterasp","goldenrasp","goldrasp","sunshinerasp"].includes(str)) {
      return [true, "white raspberry"];
    } else if (str === "marion") {
      return [true, "marionberry"];
    } else if (str === "olallie") {
      return [true, "olallieberry"];
    } else if (str === "kotota") {
      return [true, "kototaberry"];
    } else if (["buffalo", "thornybuffalo", "silverbuffalo", "bull", "rabbit", "nebraskacurrant", "russianolive"].includes(str)) {
      return [true, "buffaloberry"];
    } else if (["bunch", "canadianbunch", "cracker", "creepingdogwood", "dogwood", "dwarfcornel", "quatretemp"].includes(str)) {
      return [true, "bunchberry"];
    } else if (["choke", "aronia", "chuckle", "blackchoke", "redchoke"].includes(str)) {
      return [true, "chokeberry"];
    } else if (["chokecherry", "bitter", "bird", "blackchokecherry", "birdcherry", "westernchokecherry", "virginiabirdcherry", "chockcherry"].includes(str)) {
      return [true, "chokecherry"];
    } else if (["honey", "haskap", "swampflyhoneysuckle", "honeysuckle", "bluehoneysuckle", "haskup", "hascap", "blueberriedhoneysuckle", "sweet", "bluebell"].includes(str)) {
      return [true, "honeyberry"];
    } else if (["wine", "dew", "japanesewine", "winerasp"].includes(str)) {
      return [true, "wineberry"];
    } else if (["salmon", "joffel"].includes(str)) {
      return [true, "salmonberry"];
    } else if (["mul", "moru", "papermul", "whitemul", "chinesemul", "redmul", "mull", "redmull", "whitemull", "chinesemull", "longmul", "blackmul", "blackmull", "africanmul", "africanmull", "amorinha", "frenchmul", "frenchmull", "weepingmul", "weepingmull"].includes(str)) {
      return [true, "mulberry"];
    } else if (["cloud", "nordic", "bakeapple", "knot", "knout", "apqik", "lowbushsalmon", "averin", "evron"].includes(str)) {
      return [true, "cloudberry"];
    } else if (str==="chineseelder") {
      return [true, "Chinese elderberry"];
    } else if (str==="japaneseelder") {
      return [true, "Japanese elderberry"];
    } else if (["elder", "sambucu", "elderflower", "blackelder"].includes(str)) {
      return [true, "elderberry"];
    } else if (["snow", "ghost", "copperleafsnow"].includes(str)) {
      return [true, "snowberry"];
    } else if (["oregongrape", "oregonhollygrape", "oregongrapeholly"].includes(str)) {
      return [true, "Oregon-grape"];
    } else if (["nativecurrant", "wiryground"].includes(str)) {
      return [true, "native currant"];
    } else if (["currant", "blackcurrant", "redcurrant", "whitecurrant", "cassi", "raisinet", "garnet"].includes(str)) {
      return [true, "currant"];
    } else if (str === "logan") {
      return [true, "loganberry"];
    } else if (str === "tay") {
      return [true, "tayberry"];
    } else if (str === "young") {
      return [true, "youngberry"];
    } else if (str === "josta") {
      return [true, "jostaberry"];
    } else if (["teaberry", "wintergreen", "checker", "box", "easterntea"].includes(str)) {
      return [true, "teaberry"];
    } else if (["manzanita"].includes(str)) {
      return [true, "manzanita berry"];
    } else if (["bay", "bayrum", "waxmyrtle", "candle", "sweetgale"].includes(str)) {
      return [true, "bayberry"];
    } else if (["chinesebay", "japanesebay", "redbay", "yamamomo", "yangmei", "chinesestraw", "wax", "yum"].includes(str)) {
      return [true, "yangmei"];
    } else if (["crow", "blackcrow", "heath"].includes(str)) {
      return [true, "crowberry"];
    } else if (["farkle", "sparkle", "winterhuckle", "treesparkle"].includes(str)) {
      return [true, "farkleberry"];
    } else if (["oso", "indianplum"].includes(str)) {
      return [true, "osoberry"];
    } else if (str === "juniper") {
      return [true, "juniper berry"];
    } else if (["polar","polarblack","blackpolar","whiteblack"].includes(str)) {
      return [true, "polar berry"];
    } else if (["nanny", "sheep", "sweetviburnum"].includes(str)) {
      return [true, "nannyberry"];
    } else if (["goumi", "gumi", "cherrysilver", "natsugumi"].includes(str)) {
      return [true, "goumi"];
    } else if (["groundcherry", "golden", "peruviangroundcherry", "poha", "pichu","physalis", "capegoose", "chineselantern", "nativegoose","starhair","starhairgroundcherry"].includes(str)) {
      return [true, "groundcherry"];
    } else if (["hack", "sugar", "nettletree", "beaverwood", "sugarhack"].includes(str)) {
      return [true, "sugarberry"];
    } else if (["japanesebar", "redbar", "thunbergsbar", "mentorbar"].includes(str)) {
      return [true, "Japanese barberry"];
    } else if (["balloon", "raspberrystraw", "strawberryrasp", "fun"].includes(str)) {
      return [true, "balloon berry"];
    } else if (["beauty", "japanesebeauty"].includes(str)) {
      return [true, "beautyberry"];
    } else if (["pine", "pineapplestraw", "pineappleberry", "pineappleberrie"].includes(str)) {
      return [true, "pineberry"];
    } else if (str === "stra") {
      return [true, "strasberry"];
    } else if (["bubble", "muskstraw", "hautboystraw", "hautboy"].includes(str)) {
      return [true, "bubbleberry"];
    } else if (["deer", "talldeer", "squawhuckle", "buck", "southerngoose"].includes(str)) {
      return [true, "deerberry"];
    } else if (["miracle", "miraculou", "magic", "agbayun", "taami", "asaa", "ledidi"].includes(str)) {
      return [true, "miracle berry"];
    } else if (["hawthorn", "hawthorne", "haw","quickthorn","maytree","thornapple","whitethorn"].includes(str)) {
      return [true, "hawthorn berry"];
    } else if (["grouse", "grousewhortle", "littleleafhuckle"].includes(str)) {
      return [true, "grouseberry"];
    } else if (["rosehip", "hip", "rosehaw", "rosehep"].includes(str)) {
      return [true, "rose hip"];
    } else if (["locust", "serret", "serrette"].includes(str)) {
      return [true, "locustberry"];
    } else if (["watermelonberry", "watermelonberrie", "scoot", "liver", "twistedstalk", "wildcucumber", "claspingtwistedstalk"].includes(str)) {
      return [true, "watermelon berry"];
    } else if (["treacle", "falsespikenard", "solomonsplume", "falsesolomonsseal"].includes(str)) {
      return [true, "treacleberry"];
    } else if (["sea, seabuckthorn", "sandthorn", "sallowthorn"].includes(str)) {
      return [true, "seaberry"];
    } else if (["moose", "squashberry", "bushcran"].includes(str)) {
      return [true, "mooseberry"];
    } else if (["sandra", "fiveflavorber", "wuweizi", "chinesemagnolia"].includes(str)) {
      return [true, "Sandra berry"];
    } else if (["ink", "gall", "evergreenwinter"].includes(str)) {
      return [true, "inkberry"];
    } else if (str === "firethorn") {
      return [true, "firethorn berry"];
    } else if (["maqui", "chileanwine"].includes(str)) {
      return [true, "maqui berry"];
    } else if (["mockstraw", "falsestraw", "snakeberry", "snakeberrie"].includes(str)) {
      return [true, "mock strawberry"];
    } else if (["mos", "bogcran", "swampcran", "smallcran"].includes(str)) {
      return [true, "mossberry"];
    } else if (["moxie", "creepingsnow", "moxieplum"].includes(str)) {
      return [true, "moxieberry"];
    } else if (["orangeberry", "orangeberrie", "gin"].includes(str)) {
      return [true, "orangeberry"];
    } else if (["pricklygoose", "dog", "easternpricklygoose", "dogbramble"].includes(str)) {
      return [true, "prickly gooseberry"];
    } else if (["avignon", "persian", "french"].includes(str)) {
      return [true, "Avignon berry"];
    } else if (["porcelain", "creeper", "amurpeppervine", "wild grape"].includes(str)) {
      return [true, "porcelain berry"];
    } else if (["sapphire", "japanesesapphire", "asiaticsweetleaf"].includes(str)) {
      return [true, "sapphire berry"];
    } else if (["coffeeberry", "coffeeberrie", "californiacoffee", "californiabuckthorn"].includes(str)) {
      return [true, "coffeeberry"];
    } else if (["cascara", "cascarabuckthorn", "chittem", "chitticum"].includes(str)) {
      return [true, "cascara berry"];
    } else if (["silver", "wildolive", "wolfwillow"].includes(str)) {
      return [true, "silverberry"];
    } else if (["sun", "wonder"].includes(str)) {
      return [true, "sunberry"];
    } else if (["sloe", "blackthorn"].includes(str)) {
      return [true, "sloe berry"];
    } else if (["tangle", "dangle", "bluehuckle"].includes(str)) {
      return [true, "tangleberry"];
    } else if (["dwarfhuckle", "gopher", "bushhuckle"].includes(str)) {
      return [true, "gopherberry"]; 
    } else if (str === "silvan") {
      return [true, "silvanberry"];
    } else if (str === "tummel") {
      return [true, "tummelberry"];
    } else if (str === "water") {
      return [true, "water berry"];
    } else if (str === "veitch") {
      return [true, "veitchberry"];
    } else if (["areca", "arecanut", "betel", "betelnut", "beteljuice"].includes(str)) {
      return [true, "areca"];
    } else if (["bignay", "bugnay", "bignai", "chineselaurel", "herbertrivercherry", "queenslandcherry", "salamandertree", "curranttree"].includes(str)) {
      return [true, "bignay"];
    } else if (["bilimbi", "cucumbertree", "treesorrel"].includes(str)) {
      return [true, "bilimbi"];
    } else if (["wildsugarapple", "biriba", "aratiku", "rollinia", "rolinia"].includes(str)) {
      return [true, "biriba"];
    } else if (str === "moluccabramble" || str === "broadleafbramble") {
      return [true, "Molucca bramble"];
    } else if (str === "rum" || str === "guavaberry" || str === "guavaberrie") {
      return [true, "guavaberry"];
    } else if (str === "jabuticaba" || str === "jaboticaba" || str === "braziliangrape" || str === "braziliangrapetree") {
      return [true, "jabuticaba"];
    } else if (["ceylongooseberry", "ketembilla", "kitambilla", "kitembilla"].includes(str)) {
      return [true, "Ceylon gooseberry"];
    } else if (["cudrang", "mandarinmelon", "silkwormthorn", "che", "zhe", "chinesemul"].includes(str)) {
      return [true, "cudrang"];
    } else if (["spanishlime", "genip", "genipe", "guinep", "geniver", "ginepa", "kenep", "kene", "quenepa", "quenepe", "chenet", "canepa", "mamon", "mamn", "limoncello", "skinip", "kinnip", "huaya", "mamoncillo", "chenet", "fijianlongan"].includes(str)) {
      return [true, "genip"];
    } else if (["chileanguava", "strawberrymyrtle", "uni", "ui", "murta", "ugni", "newzealandcran", "tazzi"].includes(str)) {
      return [true, "ugniberry"];
    } else if (str === "appleberry" || str === "appledumpling" || str === "appleberrie") {
      return [true, "apple berry"];
    } else if (str==="purpleapple") {
      return [true, "purple apple berry"];
    } else if (str === "conker") {
      return [true, "conkerberry"];
    } else if (["bengalcurrant", "christsthorn", "carandasplum", "karanda", "caranda", "caranda"].includes(str)) {
      return [true, "karanda"];
    } else if (["curryleaftree", "currytree", "sweetneem"].includes(str)) {
      return [true, "curry tree fruit"];
    } else if (["emu", "dysenterybush", "dogsballs", "turkeybush", "diddlediddle"].includes(str)) {
      return [true, "emu berry"];
    } else if (str === "fal") {
      return [true, "falberry"];
    } else if (["katuk", "stargoose", "sweetleaf", "tropical asparagus"].includes(str)) {
      return [true, "katuk"];
    } else if (["otaheitegoose", "malaygoose", "tahitiangoose", "countrygoose", "starberry", "starberrie", "westindiagoose", "stargoose"].includes(str)) {
      return [true, "Otaheite gooseberry"];
    } else if (["salal", "shallon", "gaultheria", "shallonscheinbeere"].includes(str)) {
      return [true, "salal berry"]; 
    } else if (["ri", "lillypilly", "cherrysatinash", "cherryalder", "lillipilli", "smallleavedlillypilly", "clovelillipilli"].includes(str)) {
      return [true, "riberry"];
    } else if (["littlegoose", "sparrowsmango", "littlegooseberrytree"].includes(str)) {
      return [true, "little gooseberry"];
    } else if (["limeberry", "limeberrie", "limoncitong", "limoncitongkastila"].includes(str)) {
      return [true, "limeberry"]; 
    } else if (["midgen", "midyim"].includes(str)) {
      return [true, "midgen berry"];
    } else if (["moradecastilla", "andeanraspberry"].includes(str)) {
      return [true, "mora de Castilla"];
    } else if (["mortino", "mortio", "andeanblueberry", "agraz"].includes(str)) {
      return [true, "mortiño"];
    } else if (["muntrie", "emuapple", "nativecran", "munta", "munthari", "monterry"].includes(str)) {
      return [true, "muntrie berry"];
    } else if (str==="oubli" || str==="pentadin") {
      return [true, "oubli"];
    } else if (["raisintree", "japaneseraisintree", "orientalraisintree"].includes(str)) {
      return [true, "raisin tree fruit"];
    } else if (["roseleafbramble", "mauritiusrasp", "thimbleberryvanuatu", "thimbleberryvanuaturasp", "brambleofthecape"].includes(str)) {
      return [true, "roseleaf bramble"];
    } else if (str==="rosemyrtle"||str==="smallleavedmyrtle"||str==="smallleafedmyrtle") {
      return [true, "rose myrtle berry"];
    } else if (str.substring(length-5)==="rowan"||["criafol", "lamenting", "caorann", "cwicbeam", "cwicbem", "roan", "chequertree", "servicetree", "mountainash", "mountainashe", "whittypear", "chequer"].includes(str)) {
      return [true, "rowan fruit"];
    } else if (str.substring(length-9)==="whitebeam") {
      return [true, "whitebeam"];
    } else if (str==="sweetapple") {
      return [true, "sweet apple-berry"];
    } else if (["californiaholly", "toyon"].includes(str)) {
      return [true, "toyon berry"];
    } else if (str==="whiteaspen") {
      return [true, "white aspen berry"];
    } else if (["caper", "caperbush", "flindersrose"].includes(str)) {
      return [true, "caper"];
    } else if (["sapinit", "sampinit", "philippinerasp"].includes(str)) {
      return [true, "sapinit"];
    } else if (str==="whitemilkwood") {
      return [true, "white milkwood berry"];
    } else if (["fragrantmanjack", "manjack", "snottygobble", "glue", "anonang", "pinkpearl", "birdlime", "birdlimetree", "indiancherry", "booch", "lasoda", "lasura", "bhokar", "gunda"].includes(str)) {
      return [true, "glue berry"];
    } else if (["barbadosgoose","bladeapplecactu","bladeapplecacti","leafcactu","rosecactu","lemonvine"].includes(str)) {
      return [true, "Barbados gooseberry"];
    } else if (str==="flat") {
      return [true, "flatberry"];
    } else if (str==="peaberry" || str==="peaberrie" || str==="caracolillo" || str==="caracol") {
      return [true, "peaberry"];
    } else if (["turkey","pricklynightshade","shooshoo","shooshoobush","wildeggplant","peaeggplant","peaaubergine","kantsi","abeduru","kittily","susumber","terongan","tekokak","rimbang","zamorette","friegaplatos","suzumenasu","jurubeba"].includes(str)) {
      return [true, "turkey berry"];
    } else if (["angularwintercherry","ballooncherry","cutleafgroundcherry","wildtomato","camapu","physalisangulata"].includes(str)) {
      return [true, "angular winter cherry"];
    } else if (str==="southwesterngroundcherry" || str==="tomatillochiquito" || str==="physaliscaudella") {
      return [true, "southwestern groundcherry"];
    } else if (str==="husktomato" || str==="cossackpineapple" || str==="physalispruinosa") {
      return [true, "husk tomato"];
    } else if (str==="myrtillocactusgeometrizans" || /bilberrycact/.test(str) || /whortleberrycact/.test(str) || /bluecandle/.test(str) || /garambulo/.test(str)) {
      return [true, "garambulo"];
    } else if ((/allspice/.test(str) && str!=="wildallspice") || str==="pimenta" || str==="jamaiapimenta" || str==="myrtlepepper") {
      return [true, "allspice"];
    } else if (/kawakawa/.test(str) || /excelsum/.test(str)) {
      return [true, "kawakawa"];
    } else if (/wintergreen/.test(str)) {
      return [true, "wintergreen berry"];
    } else if (/saltbush/.test(str)) {
      return [true, "saltbush berry"];

    } else if (str==="crimsongloryvine" || str==="meoru" || str==="yamabudo") {
      return [true, "crimson gloryvine"];




    // grapes
    } else if (str === "muscadine" || str === "muscadinegrape") {
      return [true, "muscadine"];
    } else if (str === "riverbankgrape" || str === "frostgrape") {
      return [true, "frost grape"];
    } else if (str === "amurgrape") {
      return [true, "Amur grape"];
    } else if (str === "mustanggrape") {
      return [true, "mustang grape"];
    } else if (str==="amurgrape") {
      return [true, "Amur grape"];
    } else if (str.substring(length-10) === "bushcherry" || str === "miyamacherry") {
      return [true, "Japanese bush cherry"];
    } else if (str.substring(length-12) === "concordgrape") {
      return [true, "Concord grape"];
    } else if (str === "burmesegrape") {
      return [true, "Burmese grape"];
    } else if (/foxgrape/.test(str)) {
      return [true, "fox grape"];
    } else if (/cal(u|oo)sa/.test(str)) {
      return [true, "caloosa grape"];
    } else if (["amazongrape", "amazontreegrape", "uvilla"].includes(str)) {
      return [true, "Amazon tree-grape"];
    } else if (["californiawildgrape", "northerncaliforniagrape", "pacificgrape"].includes(str)) {
      return [true, "California wild grape"];

    } else if ((str.substring(length-5) === "grape" || str === "raisin") && !["seagrape", "baygrape"].includes(str)) {
      return [true, "grape"];
    } else if (["goose","commongoose","bittergoose","trailinggoose","hupagoose","missourigoose","luciagoose","victorsgoose","islandgoose","desertgoose","sequoiagoose","rockgoose","mountaingoose","spreadinggoose","miccosukeegoose","whitesterngoose","swampgoose","alpinegoose","trumpetgoose","gummygoose","canadiangoose","sierragoose","fuchsiafloweredgoose"].includes(str)) {
      return [true, "gooseberry"];



    // berry fails that are also fruit fails and veggie fails
    } else if (["holly", "nightshade", "deadlynightshade", "belladonna", "jimsonweed", "yew", "englishyew", "europeanyew", "cotoneaster", "ivy", "virginiacreeper", "creeper", "bittersweet", "privet", "christma", "coral", "spice", "eucalyptu", "lemonade", "poison", "scarlet", "soap", "spindle", "tetter","pokeweed","pokesalad","pokesallet"].includes(str)) {
      return [false, "too toxic"];
    } else if ((/mistletoe/.test(str) || str==="pokeweed" || str==="poke") && update<2) {
      return [false, "too toxic"];
    } else if (str === "wild") {
      return [false, "be more specific"];
    } else if (str==="bluerasp") {
      return [false, "that's just a candy flavor"];
    } else if (["halle", "beriberi", "beri", "boo", "swedish", "dave", "davebarry", "manilow", "barrymanilow", "berrymanilow", "berrybond", "bond", "pink", "barrybond", "berrywhite", "barrywhite", "barryzucker", "franken", "rodden", "generodden", "martianblue", "snozz", "snoz", "wendell"].includes(str)) {
      return [false, "nice try"];
    } else if (str === "dingle") {
      return [false, "gross"];
    } else if (str==="egg" || str==="eggs") {
      return [false, "an egg? come on"];
    } else if (["lum", "bluk", "kee", "maranga", "roseli", "cheri", "chesto", "pecha", "rawst", "aspear", "leppa", "oran", "persim", "sitru", "figy", "wiki", "mago", "aguav", "iapapa", "nanab", "wepear", "pinap", "pomeg", "kelpsy", "qualot", "hondew", "grepa", "tamato", "cornn", "magost", "rabuta", "nomel", "spelon", "pamtre", "watmel", "durin", "belue", "occa", "passho", "wacan", "rindo", "yache", "chople", "kebia", "chilan", "drash", "eggant", "nutpea", "pumkin", "shuca", "strib", "ginema", "kuo", "niniku", "topo", "touga", "yago", "coba", "payapa", "tanga", "charti", "kasib", "haban", "colbur", "babiri", "liechi", "ganlon", "salac", "petaya", "apicot", "lansat", "starf", "enigma", "micle", "custap", "jaboca", "rowap", "burnt", "pinkan", "rinka"].includes(str)) {
      return [false, "too Pokemon"];
    } else if (["firecracker","nightlock"].includes(str)) {
      return [true, "too fictional"];
    } else if (str === "bumble") {
      return [false, "that's a mixture"];

    //berry-only fails, ending berry-only section
    } else if (update<1) {
      if (["grape", "tomatoe", "tomato", "tomatillo", "cherrytomato", "cherrytomatoe", "romatomato", "romatomatoe","cherrytomato", "cherrytomatoe", "romatomato", " romatomatoe", "cucumber", "eggplant", "banana", "chili", "pepper", "chilipepper", "persimmon", "pumpkin", "kiwi", "alligatorpear", "avocadopear", "avocado", "camucamu", "camocamo", "cacari", "allspice", "pimenta", "jamaicanpimenta", "peppercorn"].includes(str) || str.substring(length-5) === "melon" || str.substring(length-6)==="pepper") {
        return [false, "only technically"];
      } else if (str === "cherry") {
        return [false, "that's a drupe"];
      } else if (str==="tom" || /tomberry/.test(str)) {
        return [false, "tomatoes are only technically berries"];
      } else return [false, "not a berry"];
    
    


    //beginning non-berry fruit section
    // https://en.wikipedia.org/wiki/List_of_culinary_fruits
    //http://www.fruitipedia.com/
    //https://www.youtube.com/playlist?list=PLvGFkMrO1ZxJ-evPigMn8Mrp0u8bH_TRE
      } else if (["abiu", "yellowstarapple", "caimitt", "caimo", "caimito", "caimitoamarillo", "maduraverde", "luma", "cauje", "temare", "abiero", "alasa", "abio"].includes(str)) {
        return [true, "abiu"]; 
      } else if (["barbadoscherry", "barbadoscherrie", "acerola", "westindiancherry", "westindiancherrie", "wildcrepemyrtle", "seriz"].includes(str)) {
        return [true, "Barbados cherry"];
      } else if (["ackee", "achee", "ackeeapple", "ayee", "acheeapple"].includes(str)) {
        return [true, "ackee"];
      } else if (str === "africancherryorange" || str === "omuburo" || str === "westafricancherryorange" || str === "ugandacherryorange") {
        return [true, "African cherry orange"];
      } else if (["wildmango", "bushmango", "africanmango", "dika", "ogbono"].includes(str)) {
        return [true, "dika"];
      } else if (str==="ethiopianmoringa" || str==="cabbagetree") {
        return [true, "Ethiopian moringa"];
      } else if (/moringa/.test(str) || ["moringo","horseradishtree","drumsticktree","benoil","benzoil","beonoiltree","benzoiltree"].includes(str)) {
        return [true, "moringa"];
      } else if (["juneplum", "ambarella", "kedondong", "buahlonglong", "goldenapple", "mangotin", "yuplon", "yupln", "goldenplum", "joboindio", "cajarana", "casmango", "amra", "frisiter", "cajmanga", "cajamanga", "vi", "v", "jewplum", "jewsplum", "tahitianapple", "pommecythere", "pommecythre", "pomcite", "pomcit"].includes(str)) {
        return [true, "ambarella"];
      } else if (["malabarchestnut","frenchpeanut","guianachestnut","provisiontree","provisiontreenut","saba","sabanut","monguba","pumpo","moneytree","moneyplant","moneyplantnut","moneytreenut"].includes(str)) {
        return [true, "Malabar chestnut"];
      } else if (["kippernut","ciphernut","cyphernut","hawknut","arnut","jarnut","earthchestnut","earthnut","hognut", "saintanthonysnut","stanthonysnut"].includes(str)) {
        return [true, "kippernut"];
      } else if (["yellowhorn", "shinyleafyellowhorn", "goldenhorn", "chinesefloweringchestnut"].includes(str)) {
        return [true, "yellowhorn"];
      } else if ((/chestnut/.test(str) || ["castanea","maraval","marsol","hundredhorse","boucherouge","comballe","marigoule"].includes(str)) && !(str==="waterchestnut")) {
        return [true, "chestnut"];
      } else if (str === "bread") {
        return [true, "breadfruit"]
      } else if (/chileanhazel/.test(str)) {
        return [true, "Chilean hazelnut"];
      } else if (str==="hazelhornbeam" || str==="ostryopsis") {
        return [true, "hazel-hornbeam"];
      } else if (["hazel", "hazelnut"].includes(str)) {
        return [true, "hazelnut"];
      } else if (["pawpaw", "papaw", "quakerdelight", "hillbillymango"].includes(str)) {
        return [true, "pawpaw"];
      } else if (["araca", "araza", "ara", "araboi", "araz"].includes(str)) {
        return [true, "araza"];
      } else if (["monk", "arhat", "luohanguo", "lohankuo", "longevity"].includes(str)) {
        return [true, "monk fruit"];
      } else if (str === "atemoya") {
        return [true, "atemoya"];
      } else if (str === "babaco") {
        return [true, "babaco"];
      } else if (["bacuri", "bacury", "bakuri", "pacuri", "pakuri", "pakouri", "packoeri", "pakoeri", "maniballi", "naranjillo", "bacurizeiro", "platonia"].includes(str)) {
        return [true, "bacuri"];
      } else if (["bael", "bengalquince", "japanesebitterorange", "stoneapple"].includes(str)) {
        return [true, "bael"];
      } else if ((str.substring(length-8) === "plantain" && !["buckshornplantain","ribwortplantain","narrowleafplantain","englishplantain","greaterplantain","longleavedplantain","broadleafplantain","broadleavedplantain","seasideplantain","seaplantain"].includes(str)) || str === "greenbanana" || str === "cookingbanana") {
        return [true, "plantain"];
      } else if (["sweetgranadilla", "grenadia", "granadilla", "maracujaacu", "grenadine", "tumbo", "gianttumbo", "passiflora", "badea", "maracujau", "barbadine"].includes(str) || str.substring(length-10) === "granadilla") {
        return [true, "granadilla"];
      } else if (["makki", "garciniamorella", "gamboge", "iravi", "chigiri", "batuan"].includes(str)) {
        return [true, "gamboge"];
      } else if (["mameysapote", "mammeesapote", "redmamey", "redmammee", "zapoterojo", "zapotecolorado"].includes(str)) {
        return [true, "mamey sapote"];
      } else if (str === "whitesapote" || str === "whitesoapapple" || str === "mexicanapple" || str === "casimiroa") {
        return [true, "casimiroa"];
      } else if (str === "bloodorange") {
        return [true, "blood orange"];
      } else if (str === "bluetongue") {
        return [true, "blue tongue fruit"];
      } else if (["bolwarra", "nativeguava", "copperlaurel"].includes(str)) {
        return [true, "bolwarra"];
      } else if (str === "boquila") {
        return [true, "boquila"];
      } else if (["calabash", "bottlemelon", "longmelon", "tasmaniabean", "whitefloweredgourd", "newguineabean"].includes(str)) {
        return [true, "calabash"];
      } else if (["crescentia", "huingo", "krabasi", "kaleba", "calabashtree"].includes(str)) {
        return [true, "huingo"];
      } else if (str.substring(length-8) === "breadnut") {
        return [true, "breadnut"];
      } else if (["bushtomato", "kangarooapple", "desertraisin", "karlumbu", "pilirta", "wamurla", "wildtomato", "solanum", "bushsultana", "potatobush", "kutjera", "australiandesertraisin"].includes(str)) {
        return [true, "bush tomato"];
      } else if (["poroporo","newzealandnightshade"].includes(str)) {
        return [true, "poroporo"];
      } else if (str==="spineleavedmonkeyorange") {
        return [true, "spine-leaved monkey-orange"];
      } else if (str === "cherapu" || str === "buttonmangosteen") {
        return [true, "button mangosteen"];
      } else if (["calamondin", "citrofortunella", "calamondinorange", "goldenlime", "calamansi", "calamandarin", "panamaorange", "calamonding", "acidorange", "muskorange", "chineseorange", "philippinelime", "kalamansi", "kalamunding"].includes(str)) {
        return [true, "calamondin"];
      } else if (["camucamu", "camocamo", "cacari"].includes(str)) {
        return [true, "camu camu"];
      } else if (["candlenut","kukui","indianwalnut","kemiri","varnishtree","varnishtreenut","nuezdelaindia","kukuinut","kekunatree","kekunatreenut","kekuna","kekunanut"].includes(str)) {
        return [true, "candlenut"]
      } else if (str === "canistel" || str === "eggfruit") {
        return [true, "canistel"];
      } else if (str === "star" || str === "carambola" || str === "fivefinger" || str==="belimbing") {
        return [true, "starfruit"];
      } else if (["mexicangiantcardon", "cardon", "cardn", "elephantcactu"].includes(str)) {
        return [true, "cardon"];
      } else if (["carobtree", "carob", "locustbean", "locusttree", "stjohnsbread"].includes(str)) {
        return [true, "carob"];
      } else if (/australiancashew/.test(str)) {
        return [true, "Australian cashew nut"];
      } else if (["cashew", "cashewtree", "cashewnut", "cashewapple"].includes(str)) {
        return [true, "cashew"];
      } else if (["cassabanana", "casbanan", "sikana", "muskcucumber"].includes(str)) {
        return [true, "cassabanana"];
      } else if (["cattleyguava", "strawberryguava", "cherryguava"].includes(str)) {
        return [true, "Cattley guava"];
      } else if (["cawesh", "poshete"].includes(str)) {
        return [true, "cawesh"];
      } else if (["monstera", "fruitsalad", "ceriman", "swisscheeseplant", "fruitsaladplant", "monstereo", "mexicanbread", "windowleaf", "penglaibanana", "balazo", "monsteriodelicio", "monster", "monsteradeliciosa"].includes(str)) {
        return [true, "monstera fruit"];
      } else if (["peruvianapplecactu", "giantclubcacti","peruvianapplecacti","giantclubcactu", "hedgecacti","hedgecactu", "cadushi", "kayush"].includes(str)) {
        return [true, "kayush"];
      } else if (str === "charichuelo" || str === "newworldmangosteen" || str==="madrono") {
        return [true, "charichuelo"];
      } else if (str === "cempedak" || str==="chempedak") {
        return [true, "cempedak"];
      } else if (["chayote", "chaya", "christophene", "chocho", "sayote", "pipinola", "pearsquash", "vegetablepear", "mirleton", "merleton", "mirliton", "militon", "chuchu", "xuxu"].includes(str)) {
        return [true, "chayote"];
      } else if (["cherimoya", "chirimoya", "chirimuya"].includes(str)) {
        return [true, "cherimoya"];
      } else if (["jujube", "reddate", "ziziphus", "chinesedate", "koreandate", "indiandate", "chinesejujube", "masau", "indianplum", "indianjujube"].includes(str)) {
        return [true, "jujube"];
      } else if (["chineseolive", "elemi", "dabai", "kembayau", "namliap", "samochin", "kana"].includes(str)) {
        return [true, "Chinese olive"];
      } else if (str === "southamericansapote" || str === "chupachupa") {
        return [true, "chupa-chupa"];
      } else if (["clusterfig", "indianfig", "goolar", "goolarfig", "gularfig"].includes(str)) {
        return [true, "cluster fig"];
      } else if (str === "cocona" || str==="solanumsessiliflorum") {
        return [true, "cocona"];
      } else if (["fuzzyfruitnightshade","fuzzy","naranjillasilvestre","chichilegua"].includes(str)) {
        return [true, "fuzzyfruit nightshade"];
      } else if (["cola", "colanut", "kola", "kolanut","kolatree","kolatreenut"].includes(str)) {
        return [true, "kola nut"];
      } else if (["coffea", "coffee", "coffeecherry", "coffeebean"].includes(str)) {
        return [true, "coffee"];
      } else if (["cupuacu", "cupuau", "cupuazu", "cupuaz", "cupuassu", "copoasu"].includes(str)) {
        return [true, "cupuaçu"];
      } else if (str === "dabai" || str === "buahdabai") {
        return [true, "dabai"];
      } else if (["deadmansfinger", "bluebeanplant", "bluebean", "bluesausage"].includes(str)) {
        return [true, "dead man's fingers"];
      } else if (["bushbanana", "silkypear", "greenvine", "desertbanana", "doubah"].includes(str)) {
        return [true, "bush banana"];
      } else if (str === "desertlime") {
        return [true, "desert lime"];
      } else if (str.substring(length-12) === "dodderlaurel") {
        return [true, "dodder-laurel"];
      } else if (["seacoconut", "cocodemer", "doublecoconut"].includes(str)) {
        return [true, "sea coconut"];
      } else if (/octopuscact/.test(str) || /caterpillarcact/.test(str) || /sourpitay[ao]/.test(str) || /organpipecact/.test(str) || /pitay[ao]agria/.test(str) || /stenocereu/.test(str) || /ccapxl/.test(str) || /daggercact/.test(str) || /iguaraya/.test(str)) {
        return [true, "sour pitaya"];
      } else if (["dragon", "pitayaroja", "strawberrypear"].includes(str) || str.substring(length-6) === "pitaya" || str.substring(length-8) === "pitahaya") {
        return [true, "dragon fruit"];
      } else if (["sabra", "nopal", "nostle", "cactusfig","abrojo","veladecoyote","tuna","xoconostle"].includes(str) || /opuntia/.test(str) || /cowstongue/.test(str) || /pricklypear/.test(str) || /cactuspear/.test(str) || /spinelesscact/.test(str) || /beavertail/.test(str)) {
        return [true, "prickly pear"];
      } else if (["langsat", "duku", "lanzone", "langsak", "lanzon", "lanzn", "buahan", "buwabuwa", "longkong", "langsep", "kokosan"].includes(str)) {
        return [true, "langsat"];
      } else if (str.substring(0,6)==="durian" || str.substring(length-6) === "durian"  || ["lai", "nyekak", "tutong", "lahong"].includes(str)) {
        return [true, "durian"];
      } else if (str.substring(length-6)==="mayhaw" || str.substring(length-8) === "hawthorn") {
        return [true, "mayhaw"];
      } else if (["acca", "pineappleguava", "guavasteen", "feijoa"].includes(str)) {
        return [true, "feijoa"];
      } else if (str.substring(0,6) === "zigzag") {
        return [true, "zig-zag vine fruit"];
      } else if (["pomelo", "pamelo", "pamplemousse", "citrusmaxima", "citrusgrandi", "pummelo", "banpeiyu", "buntan", "bontan", "crystalbuntan", "housebuntan", "tosabuntan", "japanesebuntan"].includes(str)) {
        return [true, "pomelo"];
      } else if (["applepear", "asianpear", "chinesepear", "japanesepear", "taiwanesepear", "sandpear", "koreanpear", "nashipear", "bae", "russetpear"].includes(str)) {
        return [true, "Asian pear"];
      } else if (["bizzaria", "bizzariaofflorence", "bizzariaorange", "bizaria", "bizzarria", "bizarre", "bizarreorange", "bizarria", 'florentinebizzaria'].includes(str)) {
        return [true, "Florentine Bizzaria"];
      } else if (str === "alemow") {
        return [true, "alemow"];
      } else if (str.substring(length-9) === "bloodlime") {
        return [true, "blood lime"];
      } else if (str.substring(length-9) === "roundlime") {
        return [true, "round lime"];
      } else if (["jambul", "jamun", "jamblang", "javaplum", "jambolan"].includes(str)) {
        return [true, "jambul"];
      } else if (str === "kiwi" || str === "chinesegoose") {
        return [true, "kiwi"];
      } else if (str==="loquat"||str==="japanesemedlar") {
        return [true, "loquat"];
      } else if (["litchi", "leechee", "lychi", "lychee", "litchee", "lichi", "lizhi", "liche", "liechee", "lichee"].includes(str)) {
        return [true, "lychee"];
      } else if (str==="rambutan") {
        return [true, "rambutan"];
      } else if (str === "korlan") {
        return [true, "korlan"];
      } else if (str==="clubcholla" || str==="perrito") {
        return [true, "club chollas"];
      } else if (str==="daturalanosa") {
        return [true, "Datura lanosa"];
      } else if (str==="guarana") {
        return [true, "guarana"];
      } else if (["wildsoursop", "mountainsoursop"].includes(str)) {
        return [true, "mountain soursop"];
      } else if (["guanabana", "soursop", "guanbana", "guyabano", "graviola"].includes(str)) {
        return [true, "soursop"];
      } else if (["pitomba", "cotopalo", "olhodeboi", "pitombarana", "pitombademacaco", "pitouliercomestible"].includes(str)) {
        return [true, "pitomba"];
      } else if (["snake", "salak", "salakpondoh", "salakbali", "cobra", "salacca", "salaccawallichiana"].includes(str)) {
        return [true, "snake fruit"];
      } else if (["tamarillo", "treetomato", "treetomatoe", "tomateandino", "tomateserrano", "tomatedeyuca", "sachatomate", "berenjena", "tamamoro", "tomatedearbol", "tomatederbol"].includes(str)) {
        return [true, "tamarillo"];
      } else if (["monkeypod", "blackbead", "manilatamarind", "foreigntamarind", "huamuche", "guamuche", "huamuchil", "guamuchil", "cuamuchil", "kamachile", "makhamthet", "sweetinga", "madrasthorn", "showkatmadras"].includes(str)) {
        return [true, "Manila tamarind"];
      } else if (["huito", "jagua", "genipap", "jenipapo", "caruto", "guaitil", "shagua", "xagua", "vito", "nandipa", "andip", "tapaculo", "genipapo"].includes(str)) {
        return [true, "huito fruit"];
      } else if (["icecream", "icecreambean", "joaquiniquil", "cuaniquil", "guama", "guaba", "cachiri", "pacay", "poisdoux"].includes(str)) {
        return [true, "ice-cream bean"];
      } else if (["blackberryjam", "raspberrybush", "jasminderosa", "randiaformosa"].includes(str)) {
        return [true, "blackberry jam fruit"];
      } else if (str === "hairlessrambutan"){
        return [true, "hairless rambutan"];
      } else if (str.substring(length-9)==="hardykiwi") {
        return [true, "hardy kiwi"];
      } else if (str==="jellypalm") {
        return [true, "jelly palm fruit"];
      } else if (str==="kahikatea") {
        return [true, "kahikatea"];
      } else if (str==="datilyucca" || str==="bananayucca") {
        return [true, "banana yucca"];
      } else if (["javacotton", "javakapok", "silkcotton", "samauma", "ceiba", "kapok","cottontree","redsilkcotton","bombaxceiba","redcottontree"].includes(str)) {
        return [true, "kapok"];
      } else if (["karkalla", "pigface"].includes(str)) {
        return [true, "karkalla fruit"];
      } else if (["burahol", "kepel", "keppel", "kepelapple"].includes(str)) {
        return [true, "kepel fruit"];
      } else if (str==="keule") {
        return [true, "keule fruit"];
      } else if (str==="kwaimuk") {
        return [true, "kwai muk"];
      } else if(["monkey", "barhar", "monkeyjack", "lakoocha", "giantyellowmulberry","ujuju"].includes(str)) {
        return [true, "monkey fruit"];
      } else if (["lapsi", "nepalihogplum"].includes(str)) {
        return [true, "lapsi"];
      } else if (str==="lardizabala" || str==="zabala") {
        return [true, "zabala fruit"];
      } else if (str==="lemonaspen") {
        return [true, "lemon aspen"];
      } else if (["leadtree", "leucaena", "lemonball", "littleleafleadtree", "goldenballleadtree", "wahootree", "goldenball"].includes(str)) {
        return [true, "leucaena fruit"];
      } else if (["lovilovi", "batokoplum", "tometome", "lobilobi", "lovi", "louvi"].includes(str)) {
        return [true, "lovi-lovi"];
      } else if (["lucuma, lcuma"].includes(str)) {
        return [true, "lúcuma"];
      } else if (["gandaria", "marianplum", "plummango", "mapraang", "ramania", "setar", "plango"].includes(str)) {
        return [true, "gandaria"];
      } else if (str==="mangaba") {
        return [true, "mangaba"];
      } else if (str==="manoao") {
        return [true, "manoao"];
      } else if (["terap", "marang", "joheyoak", "greenpedelai", "madang", "tarap", "timadang"].includes(str)) {
        return [true, "marang"];
      } else if (["marula", "catthorn", "cidertree", "maroola", "marulaplum", "maroolaplum", "maroela"].includes(str)) {
        return [true, "marula"];
      } else if (["breadnut", "mayanut", "mayanbreadnut", "mayannut", "ramon", "mojo", "ojite"].includes(str)) {
        return [true, "Maya nut"];
      } else if (["maypop", "purplepassionflower", "truepassionflower", "wildapricot", "wildpassionvine", "passionflower"].includes(str)) {
        return [true, "maypop"];
      } else if (["medlar", "azarole", "azerole", "mediterraneanmedlar", "sternsmedlar"].includes(str)) {
        return [true, "medlar"];
      } else if (["gnemon", "melinjo", "melindjo", "belinjo", "kuliat", "culiat", "padioat", "paddyoat"].includes(str)) {         return [true, "melinjo"];
      } else if (["pepino","pepinodulce","sweetcucumber","pepinomelon", "melonpear", "treemelon"].includes(str)) {
        return [true, "pepino"];
      } else if (str==="monkeytamarind") {
        return [true, "monkey tamarind"];
      } else if (["moracomun", "moracomn", "mora"].includes(str)) {
        return [true, "mora común"];
      } else if (["morindacitrifolia", "greatmorinda", "indianmulberry", "baiyo", "noni", "nonitree", "beachmulberry", "bluecheese"].includes(str)) {
        return [true, "noni"];
      } else if (["kusum","kusumtree","schleichera"].includes(str)) {
        return [true, "kusum"];
      } else if (str.substring(length-7)==="morinda") {
        return [true, "morinda"];
      } else if (["tasmannia", "mountainpepper", "fragrantpepper", "fragrantpepperbush", "brushpepper", "brushpepperbush", "cornishpepperleaf", "purplepepper", "purplepepperbush", "dorrigopepper", "bawbaw", "bawbawpepper", "alpinepepper", "alpinepepperbush", "tasmanianpepper"].includes(str)) {
        return [true, "Tasmanian pepper"];
      } else if (["mundu", "rata", "maphuut"].includes(str)) {
        return [true, "mundu"];
      } else if (str==="nageia") {
        return [true, "nageia"];
      } else if (str==="oilpalm"||str==="palmoil"||str==="elaeis") {
        return [true, "oil palm fruit"];
      } else if (["peanutbutter", "silverpeanutbutter", "marmelo", "friarsplum", "cachita", "muchita", "cafezinhodomato", "usuma", "yellowusuma", "yellowcoffeeofthebush", "bushtomatopaste", "bunchiosa"].includes(str)) {
        return [true, "peanut butter fruit"];
      } else if (["pequi", "souari", "souarinut"].includes(str)) {
        return [true, "pequi"];
      } else if (["puemo", "peumo", "chileanacorn"].includes(str)) {
        return [true, "puemo"];
      } else if (str==="phalsa") {
        return [true, "phalsa"];
      } else if (["pulasan", "kapulasan", "ngohkhonsan", "bulala", "panungayan"].includes(str)) {
        return [true, "pulasan"];
      } else if (str==="purpleguava") {
        return [true, "purpleguava"];
      } else if (["rimu", "redpine"].includes(str)) {
        return [true, "rimu"];
      } else if (["flatpeach", "doughnutpeach", "donutpeach", "saturnpeach", "pantao"].includes(str)) {
        return [true, "Saturn peach"];
      } else if (str==="peacherine"||str==="peacharine") {
        return [true, "peacherine"];
      } else if (["peachpalm", "chontaduro"].includes(str)) {
        return [true, "peach palm fruit"];
      } else if (["pigface", "angularseafig"].includes(str)) {
        return [true, "pigface fruit"];
      } else if (["butiacapitata", "jellypalm", "pindopalm"].includes(str)) {
        return [true, "jelly palm fruit"];
      } else if (str==="queenslandebony") {
        return [true, "Queensland ebony fruit"];
      } else if (str==="saguaro"||str==="cactus"||str==="cactu"||str==="cacti") {
        return [true, "saguaro cactus"];
      } else if (str.substring(length-12)==="sandpaperfig") {
        return [true, "sandpaper fig"];
      } else if (str==="santol"||str==="cotton") {
        return [true, "santol fruit"];
      } else if (str==="sawpalmetto") {
        return [true, "saw palmetto fruit"];
      } else if (str==="palmetto" || str==="sabal") {
        return [true, "palmetto"];
      } else if (["seagrape", "baygrape"].includes(str)) {
        return [true, "seagrape"];
      } else if (["ilama", "ilamatree", "oldwomanssapote"].includes(str)) {
        return [true, "ilama"];
      } else if (["soncoya", "sincuya", "cabezadenegro"].includes(str)) {
        return [true, "soncoya"];
      } else if (["voavanga", "tamarindoftheindies", "spanishtamarind"].includes(str)) {
        return [true, "Spanish tamarind"];
      } else if (["spanishcherry", "bulletwood", "tanjong", "mimusop"].includes(str)) {
        return [true, "tanjong"];
      } else if (str==="mimusopsbalata") {
        return [true, "mimusops balata"];
      } else if (["totara", "ttara"].includes(str)) {
        return [true, "tōtara fruit"];
      } else if (["countryalmond", "indianalmond", "malabaralmond", "seaalmond", "tropicalalmond", "falsekamani","ebelebo"].includes(str)) {
        return [true, "tropical almond"];
      } else if (str==="kamani") {
        return [true, "kamani fruit"];
      } else if (str==="wampee"||str==="wampi") {
        return [true, "wampee"];
      } else if (["jack", "whitemango", "binjai", "wani", "yaalam", "bayuno", "manggawani", "wani"].includes(str)) {
        return [true, "white mango"];
      } else if (str==="wildgrape"||str==="djabaru") {
        return [true, "djabaru"];
      } else if (str==="wildpeach") {
        return [true, "wild peach"];
      } else if (str==="kokum"||str==="wildmangosteen") {
        return [true, "kokum"];
      } else if (["wongi", "sawokacik", "caqui"].includes(str)) {
        return [true, "caqui"];
      } else if (["rattan", "yantok"].includes(str)) {
        return [true, "yantok fruit"];
      } else if (str==="bacupari" || str==="rheedia" || str==="madrone") {
        return [true, "bacupari"];
      } else if (str==="grumichama") {
        return [true, "grumichama"];
      } else if (["imbe", "africanmangosteen", "lowveldmangosteen", "livingstonesgarcinia"].includes(str)) {
        return [true, "imbe"];
      } else if (str==="kandi"||str==="cande") {
        return [true, "kandis"];
      } else if (["cacao", "cocoa"].includes(str)) {
        return [true, "cacao"];
      } else if (["ume", "umeboshi", "saltplum", "pickledplumplum", "pickledplum", "umezuke"].includes(str)) {
        return [true, "ume fruit"];
      } else if (str==="guajilote") {
        return [true, "guajilote"];
      } else if (str==="olosapo") {
        return [true, "olosapo"];
      } else if (str==="camachile"|| str==="pithecellobiumdulce") {
        return [true, "camachile"];
      } else if (str==="nipapalm") {
        return [true, "nipa palm fruit"];
      } else if (["achachair", "achachairu", "achacha"].includes(str)) {
        return [true, "achacha"];
      } else if (["kousa", "kousadogwood", "japanesedogwood", "koreandogwood", "chinesedogwood"].includes(str)) {
        return [true, "kousa"];
      } else if (["torchginger", "gingerflower", "redgingerlily", "torchlily", "wildginger", "combrang", "bungakantan", "philippinewaxflower", "indonesiantallginger", "porcelainrose"].includes(str)) {
        return [true, "torch ginger fruit"];
      } else if (["lukak", "jungledurian", "lukakjungledurian", "lukakdurian", "durianlukak", "durianpulu", "durianmerah", "nyekak", "lai", "pulu", "merah"].includes(str)) {
        return [true, "lukak"];
      } else if (["jentik", "jentikjentik", "gentik", "gentikgentik"].includes(str)) {
        return [true, "jentik jentik"];
      } else if (["rembai", "rambai", "rambi", "mafaifarang"].includes(str)) {
        return [true, "rambai"];
      } else if (["borneoavocado", "engkala"].includes(str)) {
        return [true, "engkala"];
      } else if (str==="hibiscusfruit" || (str==="hibiscu" && update<2)) {
        return [true, "hibiscus fruit"];
      } else if (["gingerbread", "doompalm", "doumpalm", "doompalmdate", "doumpalmdate", "eritrea", "goruba", "hoka", "hokatree"].includes(str)) {
        return [true, "gingerbread fruit"];
      } else if (["strawberrycactu","californiafishhookcactu","strawberrypincushion","fishhookcactu"].includes(str)) {
        return [true, "strawberry cactus fruit"];
      } else if (["momordica", "luohanguo", "lohanguo", "lohanguo", "lohankuo"].includes(str)) {
        return [true, "momordica fruit"];
      } else if (["buahmahkotadewa", "godscrown", "pau"].includes(str)) {
        return [true, "God's crown fruit"];
      } else if (["dosakai", "dosakayapappu", "dosakaicucumber", "dosakaimelon", "dosegai", "dosegaimelon"].includes(str)) {
        return [true, "dosakai"];
      } else if (["raffiapalm", "uxinut", "uxi", "uxipod", "braziliapod", "rafiapalm", "raphiapalm", "rafia", "raffia", "raphia"].includes(str)) {
        return [true, "uxi"];
      } else if (["babassupalm", "babassu"].includes(str)) {
        return [true, "babassu palm fruit"];
      } else if (["garlicfruit", "bittergarcinia", "garciniaspicata"].includes(str)) {
        return [true, "garlic fruit"];
      } else if (["cucamelon", "mousemelon", "mexicansourgherkin", "mexicanminiaturewatermelon", "miniaturewatermelon", "pepquino", "mexicansourcucumber"].includes(str)) {
        return [true, "cucamelon"];
      } else if (str==="xoconostle"||str==="xoconostlecactus") {
        return [true, "xoconostle cactus fruit"];
      } else if (["rootbeer", "piperauritum", "hojasanta"].includes(str)) {
        return [true, "root beer fruit"];
      } else if (["grugrupalm", "macaúbapalm", "coyolpalm", "macawpalm"].includes(str)) {
        return [true, "coyol palm fruit"];
      } else if (["pinguin", "penguin", "pinuela", "piuela"].includes(str)) {
        return [true, "piñuela"];
      } else if (["mahogany", "mahoganytree", "sky", "buahtunjuklangit", "xiangtianguo"].includes(str)) {
        return [true, "sky fruit"];
      } else if (["siameseroughbush", "sehora", "karora", "jindi"].includes(str)) {
        return [true, "sehora"];
      } else if (str==="jio") {
        return [true, "jio"];
      } else if (["luknieng", "jering"].includes(str)) {
        return [true, "jering"];
      } else if (["scuppernong", "scufalum", "scuppernine", "scupnun", "scufadine", "scupanon"].includes(str)) {
        return [true, "scuppernong"];
      } else if (/maripa/.test(str) || ["cuci","inayo","anaj","anaja","cocorite","cucurito","inajai","rikre","kukarit","namba","wencayapa","gaibamo","gaibawe"].includes(str)) {
        return [true, "maripa palm"];
      } else if (["mocambo","mocambotree","jaguartree","balamte","pataxte"].includes(str)) {
        return [true, "Mocambo tree fruit"];
      } else if (/argan/.test(str)) {
        return [true, "argan fruit"];
      } else if (/mulga/.test(str)) {
        return [true, "mulga apple"];
      } else if (str==="monkeypot" || str==="sapucaia" || str==="creamnut" || str==="cocodemono" || str==="paradisenut") {
        return [true, "monkey pot"];
      } else if (str==="marigoule") {
        return [true, "marigoule"];
      } else if (["wolfapple","lobeira","frutadolobo"].includes(str)) {
        return [true, "wolf apple"];
      } else if (["vilavila","stickynightshade","redbuffalobur","fireandiceplant","thefireandiceplant","fireandice","litchitomato","lycheetomato","morelledebalbis"].includes(str)) {
        return [true, "sticky nightshade"];
      } else if (str==="kuka" || str==="baobab") {
        return [true, "baobab fruit"];



      // pears
      } else if (["yapear", "chinesewhitepear", "pearple"].includes(str)) {
        return [true, "pearple"];
      } else if (str==="xinjiangpear") {
        return [true, "Xinjiang pear"];
      } else if (["ussurianpear", "harbinpear", "manchurianpear"].includes(str)) {
        return [true, "Manchurian pear"];
      } else if (["shipova", "bollwillerpear"].includes(str)) {
        return [true, "shipova"];


      // cactus
      } else if (["nightbloomingcereu","barbedwirecactu","barbedwirecactus","nightbloomingcereus","swordpear","dildocactus","dildocactu","trianglecactu","trianglecactus"].includes(str) || /rganoalado/.test(str) || /fairycastle/.test(str)) {
        return [true, "night-blooming cereus"];
      } else if (/browningiacandelaris/.test(str)) {
        return [true, "Browningia candelaris"];
      } else if (["eulychnocactus","eulychnocactu","erdisia","corryocactu","corryocactus","corryocereus","corryocereu"].includes(str)) {
        return [true, "corryocereus"];
      } else if (/buttoncact/.test(str) || /epithelantha/.test(str)) {
        return [true, "button cactus fruit"];
      } else if (/applecact/.test(str) || /moonlightcact/.test(str) || /pricklyapple/.test(str) || /tunacact/.test(str) || /reinadelanoche/.test(str) || /harrisia/.test(str) || /higochumbo/.test(str)) {
        return [true, "applecactus"];
      } else if (/chilito/.test(str) || /biznaga/.test(str)) {
        return [true, "chilito"];
      } else if (/gearstem/.test(str) || str==="cardoncillo" || str==="jacamatraca" || str==="sacamatraca" || /dahliarootedcact/.test(str)) {
        return [true, "gearstem cactus"];
      } else if (/arizonaqueenofthenight/.test(str)) {
        return [true, "Arizona queen of the night"];
      } else if (/pereskia/.test(str) || /lemonvine/.test(str) || /rosecact/.test(str) || /bayahibe/.test(str) || /barbadosgoose/.test(str) || /bladeapple/.test(str) || /leuenbergeria/.test(str)) {
        return [true, "rose cactus"];


      // citrons
      } else if (str === "buddhashand" || str === "fingeredcitron") {
        return [true, "Buddha's hand"];
      } else if (str === "kabbad") {
        return [true, "kabbad"];
      } else if (["lumia", "pearlemon", "frenchlime", "sweetlemon", "poireducommandeur", "lumi", "pommedadammo", "pomumadami", "adamsapple"].includes(str)) {
        return [true, "lumia"];
      } else if (str === "ponderosalemon") {
        return [true, "ponderosa lemon"];
      } else if (str === "yumaponderosa") {
        return [true, "Yuma Ponderosa"];
      } else if (["gardenloaf", "rhobselarsa", "alzanbu", "khoubsalarsa", "kubbad", "kubbd", "robsalarsa", "zamboa", "zanboa", "zemboua"].includes(str)) {
        return [true, "Rhobs al-Arsa"];

    // oranges and citrus
      } else if (str==="tangor" || str === "templeorange" || str === "ortanique" || str === "setoka") {
        return [true, "tangor"];
      } else if (["mandarin", "mandarinorange", "cutie", "mandarine", "mandarineorange", "christmasorange", "xmasorange", "nanfengmiju", "tachibana"].includes(str)) {
        return [true, "mandarin"];
      } else if (str.substring(0,10)==="clementine") {
        return [true, "clementine"];
      } else if (["honeytangerine", "murcott"].includes(str)) {
        return [true, "Murcott"];
      } else if (["bitterorange", "sevilleorange", "sourorange", "marmaladeorange", "bigaradeorange"].includes(str)) {
        return [true, "bitter orange"];
      } else if (["chinesebitterorange", "trifoliateorange", "japanesebitterorange", "hardyorange"].includes(str)) {
        return [true, "trifoliate orange"];
      } else if (["daidai", "kabusu", "kaiseito"].includes(str)) {
        return [true, "daidai"];
      } else if (str === "yuzu" || str === "citrusjuno" || str === "yuja") {
        return [true, "yuzu"];
      } else if (str === "sudachi") {
        return [true, "sudachi"];
      } else if (str === "tachibanaorange") {
        return [true, "tachibana orange"];
      } else if (str === "kabosu") {
        return [true, "kabosu"];
      } else if (["chinotto", "citrusmyrtifolia", "campari", "myrtleleavedorange"].includes(str)) {
        return [true, "chinotto"];
      } else if (str === "bergamotorange") {
        return [true, "bergamot orange"];
      } else if (str === "lahara") {
        return [true, "lahara"];
      } else if (["rangpur", "rangpurlime"].includes(str)) {
        return [true, "rangpur"];
      } else if (["micrantha", "citrusmicrantha", "biasong", "samuyao", "smallfloweredpapeda", "smallfruitedpapeda"].includes(str)) {
        return [true, "micrantha"];
      } else if (["mauritiuspapeda", "thailime"].includes(str) || /k[ae]f?fir/.test(str) || /makrut/.test(str)) {
        return [true, "kaffir lime"];
      } else if (str.substring(length-7)==="kumquat" || str.substring(length-7)==="cumquat") {
        return [true, "kumquat"];
      } else if (str === "limequat") {
        return [true, "limequat"];
      } else if (str === "citrangequat") {
        return [true, "citrangequat"];
      } else if (str === "orangequat") {
        return [true, "orangequat"];
      } else if (str === "procimequat") {
        return [true, "procimequat"];
      } else if (str === "sunquat") {
        return [true, "sunquat"];
      } else if (str === "yuzuquat") {
        return [true, "yuzuquat"];
      } else if (str==="mandarinquat") {
        return [true, "mandarinquat"];
      } else if (["keylime", "bartenderslime", "westindianlime", "mexicanlime", "omanilime", "dayap", "bilolo"].includes(str)) {
        return [true, "key lime"];
      } else if (["persianlime", "seedlesslime", "bearsslime", "tahitilime"].includes(str)) {
        return [true, "Persian lime"];
      } else if (["mousambi", "musambi", "sweetlime", "sweetlemon", "sweetlimetta", "limetta"].includes(str)) {
        return [true, "limetta"];
      } else if (["wildlime", "limepricklyash", "colima", "unadegato", "uadegato", "corriosa"].includes(str)) {
        return [true, "wild lime"];
      } else if (str === 'citronciru') {
        return [true, "citroncirus"];
      } else if (str === "mandelo" || str === "mandalo" || str === "cocktailgrape") {
        return [true, "mandelo"];
      } else if (str === "roughlemon") {
        return [true, "rough lemon"];
      } else if (str === "meyerlemon") {
        return [true, "Meyer lemon"];
      } else if (["oroblanco", "sweetie", "oroblancogrape"].includes(str)) {
        return [true, "oroblanco"];
      } else if (str === "ichangpapeda") {
        return [true, "Ichang papeda"];
      } else if (str === "ichanglemon" || str === "shangjuan") {
        return [true, "shangjuan"];
      } else if (["newsummerorange", "tosakonatsu", "konatsu", "hyuganatsu"].includes(str)) {
        return [true, "hyuganatsu"];
      } else if (str === "amanatsu" || str === "natsumikan" || str==="natsudaidai") {
        return [true, "amanatsu"];
      } else if (["ogonkan", "gonkan", "kimikan", "yellowmikan"].includes(str)) {
        return [true, "Ōgonkan"];
      } else if (["naartjie", "mikan", "unshumikan", "coldhardymandarin", "satsumamandarin", "satsuma", "satsumaorange"].includes(str)) {
        return [true, "satsuma orange"];
      } else if (["cherryorange", "kishumikan", "babymandarin", "tinytangerine", "minimandarin", "kishumandarin", "kishu"].includes(str)) {
        return [true, "cherry orange"];
      } else if (str === "komikan" || str==="komikanorange" || str === "sakurajimakomikanorange") {
        return [true, "komikan"];
      } else if (str === "kiyomi") {
        return [true, "kiyomi"];
      } else if (str==="dekopon") {
        return [true, "dekopon"];
      } else if (str==="iyokan"||str==="anadomikan") {
        return [true, "iyokan"];
      } else if (str==="jabara") {
        return [true, "jabara"];
      } else if (["yukou", "yuukou"].includes(str)) {
        return [true, "yukou"];
      } else if (["shequasar", "taiwantangerine", "flatlemon", "hiramilemon", "thinskinnedflatlemon", "citrusdepressa"].includes(str)) {
        return [true, "shequasar"];
      } else if (["shonangold", "shnangold", "shonangoldorange"].includes(str)) {
        return [true, "Shōnan Gold"];
      } else if (["hassaku", "hassakuorange", "jagada"].includes(str)) {
        return [true, "Hassaku orange"];
      } else if (str === "ugli") {
        return [true, "ugli fruit"];
      } else if (["humptydoo", "humptydoolime", "kakadulime", "kakadu"].includes(str)) {
        return [true, "Humpty Doo lime"];
      } else if (str==="volkamerlemon") {
        return [true, "Volkamer lemon"];
      } else if (["naranjilla", "lulo", "lulada"].includes(str)) {
        return [true, "naranjilla"];
      } else if (str==="pseudolulo") {
        return [true, "pseudolulo"];
      } else if (["toronjo","tumo","coquina","coquinameln","coquinamelon"].includes(str)) {
        return [true, "toronjo"];
      } else if (["tangelo","minneola","honeybell"].includes(str)) {
        return [true, "tangelo"];
      } else if (str.substring(length-9) === "tangerine") {
        return [true, "tangerine"];


      } else if (["indianlilac", "neem", "nimtree", "nim"].includes(str)) {
        return [true, "neem"];
      } else if (["nere", "nr", "africanlocustbean", "dodongba"].includes(str)) {
        return [true, "néré"];
      } else if (str.substring(length-12)==="monkeyorange"||["natalorange", "nsala", "spinyorange", "maboque", "fole", "doringklapper"].includes(str)) {
        return [true, "spiny monkey-orange"];
      } else if (["wildorange", "nativeorange", "merneatwakeye", "smallnativepomegranate", "mondo"].includes(str)) {
        return [true, "wild orange"];
      } else if (["waterlemon", "goldenbellapple", "jamaicanhoneysuckle", "pommeliane", "lilikoi", "orangelilikoi", "yellowlilikoi"].includes(str)) {
        return [true, "water lemon"];
      } else if (["ghostlime", "limauhantu"].includes(str)) {
        return [true, "ghost lime"];
      } else if (str==="ponkan") {
        return [true, "ponkan"];
      } else if (str==="fingersop") {
        return [true, "fingersop"];
      } else if (["asampaya", "asamkelubi"].includes(str)) {
        return [true, "asam paya"];
      } else if (str==="ridan") {
        return [true, "ridan"];
      } else if (str==="belimbinghutan") {
        return [true, "belimbing hutan"];
      } else if (str==="haruka") {
        return [true, "haruka"];
      } else if (["shatkora", "shatkoragingerlime", "melanesianpapeda", "wildorange", "cabuyao", "hatkhora", "satkara", "hatxora"].includes(str)) {
        return [true, "shatkora"];
      } else if (str==="hanza") {
        return [true, "hanza"];


     // "apples" 
      } else if (["jamaicanapple", "otaheiteapple", "otaheite", "pinksatinash", "pommerac", "malayroseapple", "malayapple", "roseapple", "jambo"].includes(str)) {
        return [true, "rose apple"];  
      } else if (str==="syzygiumjambos") {
        return [true, "syzygium jambos"];
      } else if (str==="ladyapple") {
        return [true, "lady apple"];
      } else if (["mayapple", "wildmandrake", "groundlemon", "raccoon", "mandrakeroot", "indianapple", "ducksfoot", "hogapple", "umbrellaplant"].includes(str)) {
        return [true, "mayapple"];
      } else if (["pondapple", "alligatorapple", "monkeyapple"].includes(str)) {
        return [true, "pond-apple"];
      } else if (["osageorange", "mockorange", "hedgeapple", "horseapple", "boisdarc", "bodark", "monkeyball", "bowwood", "yellowwood", "hedgeball", "monkeybrain"].includes(str)) {
        return [true, "Osage orange"];
      } else if (["iceapple", "doubpalm", "palmyrapalm", "talapalm", "toddypalm", "winepalm", "nungu"].includes(str)) {
        return [true, "nungu fruit"];
      } else if (["velvetapple", "velvetpersimmon", "mabolo", "mabolotree", "talang", "tlang", "kamagong"].includes(str)) {
        return [true, "mabolo"];
      } else if (str === "blackapple" || str === "yellowbuttonwood" || str === "yellowbulletwood") {
        return [true, "black apple"];
      } else if (str === "custardapple" || str === "bullocksheart") {
        return [true, "custard apple"];
      } else if (["elephantapple", "chulta", "woodapple"].includes(str)) {
        return [true, "elephant apple"];
      } else if (["aberiacaffra", "umkokola", "keiapple", "kaiapple", "kauapple"].includes(str)) {
        return [true, "kei-apple"];
      } else if (["mammee", "mammeeapple", "mamey", "mameyapple", "santodomingoapricot", "tropicalapricot", "southamericanapricot", "redmamey", "yellowmamey","yellowmammee"].includes(str)) {
        return [true, "mammee"];
      } else if (["cainito", "caimito", "tarapple", "starapple", "purplestarapple", "goldenleaftree", "abiaba", "pommedelait", "estrella", "milk", "aguay"].includes(str)) {
        return [true, "cainito"];
      } else if (["cockyapple", "billygoatplum", "cockatooapple"].includes(str)) {
        return [true, "cockatoo apple"];
      } else if (str === "emuapple") {
        return [true, "emu apple"];
      } else if (["smallredapple", "fibroussatinash", "apricotsatinash", "bamagafibroussatinash"].includes(str)) {
        return [true, "fibrous satinash"];
      } else if (str.substring(length-10) === "fingerlime" || str === "caviarlime") {
        return [true, "finger lime"];
      } else if (["gac", "gc", "quagac", "qugc", "spinybittergourd", "sweetgourd", "babyjack", "cochinchin gourd", "redmelon"].includes(str)) {
        return [true, "gấc"];
      } else if (["jackfruit", "jak", "fenne", "nangka","jacktree","jacknut"].includes(str)) {
        return [true, "jackfruit"];
      } else if (["gambooge", "brindle", "malabartamarind", 'pottamarind', "gummigutta"].includes(str)) {
        return [true, "gambooge"];
      } else if (str.substring(length-4)==="crab"||str.substring(length-9)==="crabapple"||["malus", "wildapple", "winterredflesh", "winterredfleshapple", "goldenfruitapple"].includes(str)) {
        return [true, "crab apple"];
      } else if (["javaapple", "semarangroseapple", "waxapple", "waxjambu", "jambuair"].includes(str)) {
        return [true, "wax jambu"];
      } else if (str==="mangroveapple"||str==="crabapplemangrove") {
        return [true, "mangrove apple"];
      } else if (str==="karira") {
        return [true, "karira"];
      } else if (["kokoua","huou","solanumrepandum"].includes(str)) {
        return [true, "kokoua"];

      } else if (/lipstick/.test(str) || /achiote/.test(str) || /annatto/.test(str) || /bijol/.test(str)) {
        return [true, "annatto"];


      //plums and apricots
      } else if (str==="sealemon" || str==="piut" || str==="tallowwood") {
        return [true, "tallow wood fruit"];
      } else if (str==="desertapricot") {
        return [true, "desert apricot"];
      } else if (["brownplum", "plumpine", "plumpineplum", "illawarraplum", "illawarra"].includes(str)) {
        return [true, "Illawarra plum"];
      } else if (["malabarplum", "champakka", "champoo", "chompu", "paute", "pautee", "golapjaam", "yambo", "dampo", "tampoy", "tampoi"].includes(str)) {
        return [true, "Malabar plum"];
      } else if (["armenianplum", "tibetanapricot", "ansuapricot"].includes(str)) {
        return [true, "ansu apricot"];
      } else if (str === "ketcot") {
        return [true, "ketcot"];
      } else if (["pigeonplum", "pigeonseagrape", "tietongue", "doveplum"].includes(str)) {
        return [true, "doveplum"];
      } else if (["nonda", "nondaplum", "nondatree", "nundaplum", "nunda", "parinari"].includes(str)) {
        return [true, "nonda plum"];
      } else if (str === "purpleapricot" || str === "blackapricot") {
        return [true, "purple apricot"];
      } else if (["cocoplum", "paradiseplum", "abajeru", "icaco"].includes(str)) {
        return [true, "cocoplum"];
      } else if (str === "burdekinplum") {
        return [true, "Burdekin plum"];
      } else if (["peachpalm", "peachplum", "chontaduro", "pewa", "pupunha"].includes(str)) {
        return [true, "peach plum"];
      } else if (str === "gheysi" || str === "barge") {
        return [true, "gheysi"];
      } else if (str === "beachplum" || str === "seasideplum") {
        return [true, "beach plum"];
      } else if (str === "siberianapricot") {
        return [true, "Siberian apricot"];
      } else if (str === "scoutapricot" || str === "manchurianapricot") {
        return [true, "Scout apricot"];
      } else if (str === "blackthorn" || str.substring(length-4) === "sloe") {
        return [true, "sloe"];
      } else if (str.substring(length-7) === "bullace") {
        return [true, "bullace"];
      } else if (str.substring(length-6) === "damson" || str.substring(length-10) === "damsonplum") {
        return [true, "damson"];
      } else if (str === "darvazplum") {
        return [true, "Darvaz plum"];
      } else if (str === "lemonplum") {
        return [true, "lemon plum"];
      } else if (str === "mexicanplum") {
        return [true, "Mexican plum"];
      } else if (str.substring(0, 9) === "perdrigon") {
        return [true, "perdrigon"];
      } else if (["sweetplum", "mockbuckthorn", "sageretia", "chinesesweetplum"].includes(str)) {
        return [true, "sageretia"];
      } else if (str === "cherryplum" || str === "myrobalanplum") {
        return [true, "cherry plum"];
      } else if (["atanga", "safou", "africanpear", "africanplum", "bushplum", "bushpear", "nsafu", "bushbutter", "butter"].includes(str)) {
        return [true, "safou"];
      } else if (str === "victoriaplum") {
        return [true, "Victoria plum"];
      } else if (["sandplum", "chickasawplum", "cherokeeplum", "floridasandplum", "sandhillplum"].includes(str)) {
        return [true, "Chickasaw plum"];
      } else if (str === "hybridplum") {
        return [true, "hybrid plum"];
      } else if (str === "alucha") {
        return [true, "alucha"];
      } else if (str === "longan" || str === "japaneseplum" || str === "chineseplum" || str === "japanesemedlar") {
        return [true, "longan"];
      } else if (["hogplum", "creekplum", "wildgooseplum"].includes(str)) {
        return [true, "hog plum"];
      } else if (["flatwoodsplum", "sloeplum"].includes(str)) {
        return [true, "sloe plum"];
      } else if (str==="yellowmombin") {
        return [true, "yellow mombin"];
      } else if (str.substring(length-6)==="mombin" || ["jocote", "jobito", "cirguela", "ciriguela", "cirigela", "ciruela", "jamaicaplum", "jamaicanplum"].includes(str)) {
        return [true, "jocote"];
      } else if (["snakewood", "nakedwood", "greenheart", "colubrina"].includes(str)) {
        return [true, "snakewood fruit"];
      } else if (str === "japaneseapricot" || str === "greenplum") {
        return [true, "Japanese apricot"];
      } else if (str === "apricotplum" || str === "simonplum") {
        return [true, "apricot plum"];
      } else if (str.substring(length-10) === "yellowplum" || str === "yellowsweetplum" || str === "largeyellowsweetplum" || str==="munydjudj" || str === "wildplum") {
        return [true, "wild plum"];
      } else if (str === "canadaplum" || str === "canadianplum" || str === "blackplum") {
        return [true, "Canada plum"];
      } else if (["klamathplum", "oregonplum", "pacificplum", "sierraplum", "gosi"].includes(str)) {
        return [true, "Klamath plum"];
      } else if (str.substring(0, 7) === "zwetsch" || ["cacaksbeste", "elena", "hauszwetschge", "ortenauer", "schmootsch", "powidl", "daveigne"].includes(str)) {
        return [true, "zwetschge"];
      } else if (str === "gage" || str === "greengage") {
        return [true, "gage"];
      } else if (str === "mirabelle" || str === "mirabelleplum") {
        return [true, "mirabelle plum"];
      } else if (["pluot", "aprium", "apriplum", "plumcot"].includes(str)) {
        return [true, "pluot"];
      } else if (str === "nectaplum" || str === "spicezee") {
        return [true, "nectaplum"];
      } else if (str === "peacotum") {
        return [true, "peacotum"];
      } else if (["rukam", "indianplum", "indianprune", "governorsplum"].includes(str)) {
        return [true, "rukam"];
      } else if (["gubinge", "kakaduplum", "greenplum", "saltyplum", "murunga", "mador"].includes(str)) {
        return [true, "Kakadu plum"];
      } else if (["desertquandong", "northernsandalwood", "sandalwood", "truesandalwood", "burdardu", "quandong", "nativepeach","ming","coolyar"].includes(str)) {
        return [true, "quandong"];
      } else if (str==="allahabadisurkha") {
        return [true, "Allahabadi Surkha"];


      // "cherries"
      } else if (str === "rumcherry" || str.substring(length-11) === "blackcherry" || str === "capulincherry") {
        return [true, "black cherry"]; 
      } else if (["brushcherry", "scrubcherry","watergum","brushcherrie","scrubcherrie","sourcherry"].includes(str) || /syzygium/.test(str) || /satinash/.test(str) || /lil?l[iy]pill?[iy]/.test(str)) {
        return [true, "brush cherry"];
      } else if (["aabang", "cedarbaycherry", "mountainstopper", "nioi"].includes(str)) {
        return [true, "Cedar Bay cherry"];
      } else if (["cherryballart", "cypresscherry", "nativecherry"].includes(str)) {
        return [true, "cherry ballart"];
      } else if (str === "cherryoftheriogrande") {
        return [true, "cherry of the Rio Grande"];
      } else if (["corneliancherry", "europeancornel", "corneliancherrydogwood"].includes(str)) {
        return [true, "Cornelian cherry"];
      } else if (["surinamcherry", "surinamecherry", "cayennecherry", "cerisiercherry", "pitanga", "braziliancherry", "starcherry", "pitangatuba"].includes(str)) {
        return [true, "pitanga"];
      } else if (["jamaicacherry", "jamaicancherry", "calabur", "panamaberry", "strawberrytree", "ornamentalcherry", "jamfruittree", "jam", "singaporecherry", "westindiancherry", "talok", "boisramier", "cereza", "memiso", "bolaina", "chitato", "pasito", "yumanaza", "latire", "ratile", "calbura", "muntingia"].includes(str)) {
        return [true, "Jamaica cherry"];
      } else if (["southamericancherry", "courbaril", "westindianlocust", "braziliancopal", "amamigum", "braziliancherry", "stinkingtoe", "oldmanstoe", "stinktoe", "jatoba", "jatob", "toe"].includes(str)) {
        return [true, "stinking toe fruit"];
      } else if (["kundong", "brunaicherry", "asamauraur", "borneocherry", "redmangosteen"].includes(str)) {
        return [true, "kundong"];
      } else if (["wateryroseapple", "waterapple", "bell"].includes(str)) {
        return [true, "bell fruit"];



      // melons
      } else if (str === "canarymelon") {
        return [true, "canary melon"];
      } else if (["bittermelon", "bitterapple", "bittergourd", "bittersquash", "balsampear","cerasse"].includes(str)) {
        return [true, "bitter melon"];
      } else if (str.substring(0,8) === "honeydew" || str === "bailanmelon") {
        return [true, "honeydew"];
      } else if (["cantaloupe", "canteloupe","cantalope","cantelope","muskmelon", "mushmelon", "sweetmelon", "rockmelon", "spanspek", "cantaloupemelon", "sugarmelon", "charentais", "charentaismelon", "yubariking"].includes(str) || str.substring(0,10)==="cantaloupe") {
        return [true, "cantaloupe"];
      } else if (str === "persianmelon") {
        return [true, "Persian melon"];
      } else if (["santaclausmelon", "santaclau", "christmasmelon", "pieldesapo", "toadskinmelon"].includes(str)) {
        return [true, "Santa Claus melon"];
      } else if (str === "galiamelon" || str === "sarda") {
        return [true, "Galia melon"];
      } else if (["orientalpicklingmelon", "wolgwa", "shirouri"].includes(str)) {
        return [true, "shirouri"];
      } else if (str.substring(0,10) === "barattiere") {
        return [true, "barattiere"];
      } else if (str.substring(0,9) === "carosello") {
        return [true, "carosello"];
      } else if (str === "cranemelon") {
        return [true, "Crane melon"];
      } else if (["hamimelon", "chinesehamimelon", "snowmelon"].includes(str)) {
        return [true, "hami melon"];
      } else if (str === "koreanmelon" || str === "chamoe") {
        return [true, "Korean melon"];
      } else if (["montrealmelon", "montralmelon", "montrealmarketmuskmelon", "montrealnutmegmelon"].includes(str)) {
        return [true, "Montreal melon"];
      } else if (["waxgourd", "wintermelon", "ashgourd", "whitegourd", "tallowgourd", "ashpumpkin", "chinesepreservingmelon"].includes(str)) {
        return [true, "winter melon"];
      } else if (str.substring(0,5) === "egusi" || str==="colocynth" || str==="vineofsodom") {
        return [true, "egusi"];
      } else if (["watermelon", "kaffirmelon", "tsammamelon", "lerotse", "karkoer", "bitterboela", "lehapu", "sweetwatermelon", "seedlesswatermelon", "xigua", "xiguamelon"].includes(str)) {
        return [true, "watermelon"];
      } else if (["hornedmelon", "kiwano", "africanhornedcucumber", "hedgedgourd", "melano", "jellymelon", "africanhornedmelon"].includes(str)) {
        return [true, "kiwano"];
      } else if (str === "argosmelon" || str === "argetiko") {
        return [true, "Argos melon"];
      } else if (str.substring(0,6) === "casaba") {
        return [true, "casaba"];
      } else if (str === "kolkhoznitsamelon" || str === "kolkhoznitsa") {
        return [true, "Kolkhoznitsa melon"];
      } else if (str === "spritemelon") {
        return [true, "Sprite melon"];
      } else if (str === "tigermelon") {
        return [true, "tiger melon"];
      } else if (str === "sharlynmelon") {
        return [true, "Sharlyn melon"];
      } else if (str.substring(0,8) === "crenshaw" || str.substring(0,8) === "cranshaw") {
        return [true, "Crenshaw melon"];
      } else if (str==="acanthosicyoshorridus" || str==="nara") {
        return [true, "naras"];
      } else if (str==="waraqu") {
        return [true, "waraqu"];
      } else if (str==="namibtsamma") {
        return [true, "Namib tsamma"];

      } else if (["sehe","patawa","hungurahua","mingucha","oenocarpusbataua","jesseniabataua"].includes(str)) {
        return [true, "patawa"];
      } else if (/nipa/.test(str)) {
        return [true, "nipa palm"];
      } else if (str==="sugarpalm") {
        return [true, "sugar palm"];
      } else if (["football","pangiumedule","keluak","keluwak","kepayang","payang","pangi"].includes(str)) {
        return [true, "football fruit"];

      } else if (str==="physaliscoztomatl") {
        return [true, "Physalis coztomatl"];
      } else if (str==="tzimbalo") {
        return [true, "tzimbalo"];
      } else if (/peumus/.test(str) || /boldo/.test(str)) {
        return [true, "boldo"];


      // generic classics 
      } else if (["roselle","jamaicansorrel","carcade","belchanda","tengamora","galda","amile","hanserong","sillosougri","mwita","dachang","datchang","gongura","isapa","grajiap","chinbaung","luoshenhua"].includes(str)) {
        return [true, "roselle"];
      } else if (str.substring(length-6) === "sapote" || ["soapapple", "sapodilla", "chapote", "nisberry", "naseberry", "chikoo", "chiku", "chico", "chickoo", "sapota", "longmut", "lngm", "chocolatepudding", "zapote", "zapoteprieto", "bernicker", "mossman", "maher", "rickslate", "merida", "mrida", "valesca"].includes(str)) {
        return [true, "sapote"];
      } else if (str.substring(length-3) === "fig" || ["sycomore", "sycamore", "figmul"].includes(str)) {
        return [true, "fig"];
      } else if (str.substring(length-4) === "date" || str.substring(length-8) === "datepalm") {
        return [true, "date"];
      } else if (str.substring(length-6) === "quince") {
        return [true, "quince"];
      } else if (str.substring(length-7) === "apricot") {
        return [true, "apricot"];
      } else if (str.substring(length-7) === "coconut" || str === "cocoanut" || str==="copra") {
        return [true, "coconut"];
      } else if (str.substring(length-6) === "banana") {
        return [true, "banana"];
      } else if (str.substring(length-5) === "guava") {
        return [true, "guava"];
      } else if (str.substring(length-6) === "citron" || str === "etrog" || str === "esrog") {
        return [true, "citron"];
      } else if (str.substring(length-4) === "lime") {
        return [true, "lime"];
      } else if (str.substring(length-5) === "lemon" || ["bonniebrae", "sorrento", "femminellostteresa", "eureka", "limoncello"].includes(str)) {
        return [true, "lemon"];
      } else if (str.substring(length-6) === "orange") {
        return [true, "orange"];
      } else if (str.substring(length-5) === "peach" && !/gardenpeach/.test(str)) {
        return [true, "peach"];
      } else if (str.substring(length-9) === "nectarine") {
        return [true, "nectarine"];
      } else if (str.substring(length-9) === "pineapple") {
        return [true, "pineapple"];
      } else if (str.substring(length-5)==="nance" || ["changunga", "muruci", "murui", "nanche", "chacunga", "craboo", "kraabu", "savannaserrete", "savannaserret", "goldenspoon"].includes(str)) {
        return [true, "nance"];
      } else if (str.substring(length-8)==="tamarind" || str === "tamarindo" || str === "tamon" || str === "tamn") {
        return [true, "tamarind"];
      } else if (str.substring(length-11)==="pomegranate"||str==="pomegranite") {
        return [true, "pomegranate"];
      } else if (str.substring(length-6)==="papaya") {
        return [true, "papaya"];
      } else if (str.substring(length-7)==="passion" || ["maracuja", "maracuj", "maracuy", "maracuya", "fruitdelapassion", "mburukuja", "lilikoi", "grenadille", "parcha"].includes(str)) {
        return [true, "passionfruit"];
      } else if ((str.substring(length-6) === "cherry" || str === "gean") && !(str==="mattswildcherry") && !(str==="japanesecherry")) {
        return [true, "cherry"];
      } else if (["grapefruit", "oroblanco", "rubyred", "pinkgrape", "rubyredgrape", "riostar", "riostargrape", "whitemarshgrape", "starruby", "starrubygrape"].includes(str)) {
        return [true, "grapefruit"];
      } else if (str.substring(length-10)==="mangosteen" || str==="mangostan") {
        return [true, "mangosteen"];
      } else if (str.substring(length-5)==="mango" || ["adaulfo", "adolpho", "ataulfo"].includes(str)) {
        return [true, "mango"];
      } else if (str.substring(length-9) === "persimmon" || ["simmon", "possumwood", "possumapple", "dateplum", "kaki", "hoshigaki", "gotgam", "dangam", "vainiglia", "ormond", "tanenashi", "tamopan", "maru", "tipo", "cioccolatino", "hachiya", "jiro", "fuyu", "goma", "tsurunoko", "hyakume", "blacksapote", "spanishpersimon", "sharon", "diospyro"].includes(str)) {
        return [true, "persimmon"];
      } else if ((str.substring(length-4) === "plum" || str==="prune") && !(str==="dirigibleplum")) {
        return [true, "plum"];
      } else if (str.substring(length-4) === "pear" && str!=="alligatorpear") {
        return [true, "pear"];
      } else if ((str.substring(length-5) === "apple" && !["himalayanmayapple","indianmayapple"].includes(str)) || ["alice", "reddeliciou", "ambrosia", "bramley", "discovery", "fuji", "grannysmith", "goldendeliciou", "honeycrisp", "jonagold", "lobo", "gala", "mcintosh", "macintosh", "sugarbee", "summerred", "pinklady"].includes(str) ) {
        return [true, "apple"];
      } else if (["kurandaquandong","ebonyheart","greynut","nuttree","nutwood","johnstoneriveralmond"].includes(str)) {
        return [true, "Johnstone River almond"];
      } else if (str.substring(length-6)==="almond" && str !== "earthalmond") {
        return [true, "almond"];


    // fruit fails that are also veggie fails
    } else if (["melon", "citrus", "citru", "pome", "papeda", "bushtucker", "tropical"].includes(str)) {
      return [false, "be more specific"];
    } else if (["devil", "yoshi", "gumgum", "magmag", "bloombloom", "glintglint", "flameflame", "darkdark", "tonton", "kilokilo", "waxwax", "stickstick", "gomugomu", "meramera", "smile", "opeope", "opop", "yamiyami", "itoito", "awaawa", "humanhuman", "oxox", "ushiushi", "sarasara", "golgol", "zoan", "paramecia", "logia", "gomugomunomi", "chopchop", "bubblebubble", "smoothsmooth", "poppop", "bombbomb", "paopu", "pallie", "wroshyr", "bananasinpyjama", "giantpeach", "annoyingorange","paopu","mut","banora","banorawhite","dirigibleplum"].includes(str)) {
      return [false, "too fictional"]; 


// ending fruit section, giving fruit-only fails
    } else if (update<2) {
      if (["avocado", "tomatillo", "alligatorpear", "avocadopear", "tomato", "tomatoe", "cidra", "zidra", "cherrytomato", "cherrytomatoe", "romatomato", "romatomatoe", "cucumber", "eggplant", "chili", "pepper", "chilipepper", "pumpkin", "walnut", "easternblackwalnut", "blackwalnut", "butternut", "whitewalnut", "armeniancucumber", "corn", "cornkernal", "squash", "pea", "greenpea", "peapod", "pistachio", "almond", "macadamia", "maroochinut", "queenslandnut", "macadamianut", "baupienut", "hawaiinut", "peanut", "pecan", "pecannut", "pigeonpea", "pili", "pilinut", "queenslandnut", "vanilla", "allspice", "pimenta", "jamaicanpimenta", "peppercorn", "luffa", "ridgedgourd", "ridgegourd", "zucchini", "acorn", "oaknut", "ginkgo", "corn","maize","gingkonut", "gingkobalboa", "ginger"].includes(str) || str.substring(length-5)==="olive" || str.substring(length-6)==="pepper" || str.substring(length-4)==="bean") {
        return [false, "only technically"];
      } else if (["sakura","cherryblossom","japanesecherry"].includes(str)) {
        return [false, "the blossoms are eaten, not the fruit"];
      } else if (/nutmeg/.test(str) || str==="mace") {
        return [false, "nutmeg fruit is too poisonous"];
      } else return [false, "not a fruit"];

    } else return vegVerify(str, length);

}


export default berVerify;


// Should I do all the apple and potato cultivars?

// go through:

// https://en.wikipedia.org/wiki/Category:Food_plant_cultivars
// https://en.wikipedia.org/wiki/Category:Edible_plants
// https://en.wikipedia.org/wiki/Category:Vegetables
// https://en.wikipedia.org/wiki/Microgreen
// https://en.wikipedia.org/wiki/List_of_vegetables

// check on:
// https://en.wikipedia.org/wiki/List_of_culinary_fruits
// https://en.wikipedia.org/wiki/List_of_citrus_fruits

// done:
// https://en.wikipedia.org/wiki/List_of_edible_cacti
// https://en.wikipedia.org/wiki/List_of_culinary_herbs_and_spices
// https://en.wikipedia.org/wiki/Herbal_tea