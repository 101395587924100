import React from 'react';
import './Tutorial.css';
import TutorialBox from './TutorialBox/TutorialBox';
import { Flipper } from 'react-flip-toolkit';

const Tutorial = ( {status, showHeader, showBush, changeStatus} ) => {

	return (
		<Flipper flipKey={status}>
			<div>
				<TutorialBox showHeader={showHeader} status={status} showBush={showBush} changeStatus={changeStatus}/>
			</div>
		</Flipper>
	);
}

export default Tutorial;