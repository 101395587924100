import React from 'react';
import './Title.css';

const Title = ( props ) => {
	return (
		<div className="titleBox">
  			<h1 id="title">{props.title}</h1>
		</div>
	);
}

export default Title;