import React, { Component } from 'react';
import ReactHowler from 'react-howler';
import MusicMenu from '../MusicMenu/MusicMenu';
import Songs from '../../../../constants/SongInfo';

class MusicBox extends Component {

  state = {
    songLoaded: "summer"
  }

  changeSong = (song) => {
    this.setState(prev=>({songLoaded: song}))
    this.props.setBushSpeed(Songs[song].bpm, Songs[song].rockspeed);
  }

  nextSong = () => {
    const playlist = this.props.songsUnlocked.filter(song => !this.props.songsOffPlaylist.includes(song));
    let index = this.props.songsUnlocked.indexOf(this.state.songLoaded);
    let songfound = false;
    for (let i=0; i<this.props.songsUnlocked.length && !songfound; i++) {
      index = (index+1)%this.props.songsUnlocked.length;
      if (playlist.includes(this.props.songsUnlocked[index])) {
        songfound = true;
        this.changeSong(this.props.songsUnlocked[index]);
      }
    }
    if (!songfound) {
      this.setState(prev=>({songLoaded: "summer", playing: false}));
      this.props.setBushSpeed(Songs.summer.bpm, Songs.summer.rockspeed);
      console.log("Error in changing to next playlist song");
    }
  } 

  prevSong = () => {
    const playlist = this.props.songsUnlocked.filter(song => !this.props.songsOffPlaylist.includes(song));
    let index = this.props.songsUnlocked.indexOf(this.state.songLoaded);
    let songfound = false;
    for (let i=0; i<this.props.songsUnlocked.length && !songfound; i++) {
      index = index-1;
      if (index<0) {index = this.props.songsUnlocked.length-1}
      if (playlist.includes(this.props.songsUnlocked[index])) {
        songfound = true;
        this.changeSong(this.props.songsUnlocked[index]);
      }
    }
    if (!songfound) {
      this.setState(prev=>({songLoaded: "summer", playing: false}));
      this.props.setBushSpeed(Songs.summer.bpm, Songs.summer.rockspeed);
      console.log("Error in changing to previous playlist song");
    }
  }

  purchaseSong = (song) => {
    this.props.addSong(song);
    this.changeSong(song)
  }


  render () {
    const menu = <MusicMenu   reveal={this.props.reveal}
                              playToggle={this.props.musicPlayToggle}
                              purchaseSong={this.purchaseSong}
                              songsUnlocked={this.props.songsUnlocked}
                              entries={this.props.entries}
                              numUpgrades={this.props.numUpgrades}
                              songLoaded={this.state.songLoaded}
                              playing={this.props.playing}
                              addToPlaylist={this.props.addToPlaylist}
                              removeFromPlaylist={this.props.removeFromPlaylist}
                              songsOffPlaylist={this.props.songsOffPlaylist}
                              nextSong={this.nextSong}
                              prevSong={this.prevSong}
                              />;
    return (
    	<>
        	<ReactHowler  src={Songs[this.state.songLoaded].audiofile}
                        playing={this.props.playing}
                        onEnd={this.nextSong}
            />
          {this.props.open ? menu : null }
      </>
    )
  }
}

export default MusicBox;