import React from 'react';
import Modal from '../../UI/Modal/Modal';

const MobileWarning = (props) => {

	const para1 = props.mobile ? "Mobile devices are too small." : "Your browser window is a little too small.";
	const para2 = props.mobile ? "Please launch Clickaberry on a desktop or laptop computer." : "Please increase your browser window size.";

	return (
		<Modal show={true} opaqueBack={true} mobileSize={true} smallTop={true}>
			<p style={{fontSize: "1.2em"}}> You need a big basket to pick blueberries. </p>

			<p style={{fontSize: "1.2em"}}> {para1} </p>

			<p style={{fontSize: "1.2em"}}> {para2} </p>
		</Modal>
	);
}

export default MobileWarning;