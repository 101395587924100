import React from 'react';
import './InventoryItem.css';

const InventoryItem = (props) => {
		return (
				<div className="inventoryRow">{`${props.currName}: ${props.currCount}`}</div>
			);
}

export default InventoryItem;


// There's no state here; this is a stateful component just so that I can use the lifestyle method.
// This will be a great place to test out a React hook when they arrive.