import React from 'react';
import './DiscTitleBar.css';

import { Flipped } from 'react-flip-toolkit';

const DiscTitleBar = ( props ) => {
	return (
			<div className="discTitleBar">
				<h2 className={"discTitle"}>
					<Flipped inverseFlipId={props.cat} scale>
						<span className="discIcon">{props.icon}</span>
					</Flipped>
					<Flipped inverseFlipId={props.cat} scale>
					<div className={props.animate}
							onAnimationEnd={props.endTitleAnimation}>
							{props.titleText}
					</div>
					</Flipped>
				</h2>
			</div>
	);
}

export default DiscTitleBar;