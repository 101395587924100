import React from 'react';
import ConsoleButton from './ConsoleButton/ConsoleButton';
import classNames from 'classnames';
import './Console.css';

const Console = ( props ) => {

	let classes = classNames("console", props.consoleRevealed ? "consoleshown" : "consolehidden");

	return (
		<div className={classes}>
     		<ConsoleButton 	menuType="research" 
     						categories={props.categories}
     						inventory={props.inventory}
     						numUpgrades={props.numUpgrades}
                            increaseCatUpgrades={props.increaseCatUpgrades}
                            removeFromInventory={props.removeFromInventory}
                            updateTitle={props.updateTitle}
                            changeTutorial={props.changeTutorial}
                            jambandPurchased={props.showMusicButton}
                            revealMusicButton={props.revealMusicButton}
                            show={true}
                            />
            <ConsoleButton  menuType="music"
                            show={props.showMusicButton}
                            categories={props.categories}
                            numUpgrades={props.numUpgrades}
                            entries={props.entries}
                            changeTutorial={props.changeTutorial}
                            songsUnlocked={props.songsUnlocked}
                            addSong={props.addSong}
                            addToPlaylist={props.addToPlaylist}
                            removeFromPlaylist={props.removeFromPlaylist}
                            songsOffPlaylist={props.songsOffPlaylist}
                            musicPlaying={props.musicPlaying}
                            musicPlayToggle={props.musicPlayToggle}
                            setBushSpeed={props.setBushSpeed}
                            />
            <ConsoleButton  menuType="settings" 
                            toggleResetModal={props.toggleResetModal}
                            showResetModal={props.showResetModal}
                            toggleCommentModal={props.toggleCommentModal}
                            changeTutorial={props.changeTutorial}
                            load={props.load}
                            save={props.save}
                            show={props.showSettingsButton} />
   	    </div>
	);
}

export default Console;