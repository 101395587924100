import React, { PureComponent } from 'react';
import D from '../../../constants/DiscoveryBuildConstants';
import { C } from '../../../constants/DevSwitch.js';
import DiscText from './DiscText/DiscText';
import DiscInput from './DiscInput/DiscInput';
import DiscList from './DiscList/DiscList';
import DiscTitleBar from './DiscTitleBar/DiscTitleBar';
import './Discovery.css';

class Discovery extends PureComponent {

	// I'm slightly worried about this being a PureComponent. If something goes wrong, it's worth
	// thinking about whether this is to blame.  ALso, it might make sense to push the pure component
	// status up one tree level, to Discoveries.

	state = {
		currentAnimation: " invisibleDisc",
		titleAnimation: "",
		goodPulsingItems: [],
		badPulsingItems: [],
	}

	discRef = React.createRef();

	startGoodPulseAnimation = (entry) => {
		if (!this.state.goodPulsingItems.includes(entry)) {
			this.setState(oldstate=>({goodPulsingItems: [...oldstate.goodPulsingItems, entry]}));
		}
	}

	startBadPulseAnimation = (entry) => {
		if (!this.state.badPulsingItems.includes(entry)) {
			this.setState(oldstate=>({badPulsingItems: [...oldstate.badPulsingItems, entry]}));
		}
	}

	endGoodPulseAnimation = () => {
		this.setState(oldstate=>({goodPulsingItems: oldstate.goodPulsingItems.slice(1)}));
	}

	endBadPulseAnimation = () => {
		this.setState(oldstate=>({badPulsingItems: oldstate.badPulsingItems.slice(1)}));
	}

	endDiscAnimation = () => {
		this.setState({currentAnimation: ""});
	}

	endTitleAnimation = () => {
		this.setState({titleAnimation: ""});
	}

	componentDidMount() {
		if (!this.props.justLoaded) {
			this.props.addToEntries(this.props.cat, D[this.props.cat].firstItem);
		}
		this.discRef.current.scrollIntoView({behavior: "smooth", block: "center"});
		setTimeout(()=>{this.setState({currentAnimation: " zoomIn"})}, 500);
	}

	componentDidUpdate( prevprops ) {
		if (prevprops.upgrades < this.props.upgrades && !this.props.justLoaded) {	
			this.discRef.current.scrollIntoView({behavior: "smooth", block: "center"});
			this.setState({	currentAnimation: " tada",	
							titleAnimation: " animateTitle" });
		}
	}


	render() {
		const cat = this.props.cat;
		const upgradeIndex = this.props.upgrades-1;
		const entriesArray = this.props.entries.split(" ,");
		const iconArray = D[cat].icon;
		const boxClass = entriesArray.length>C.DISCOVERYBOX_DOUBLEWIDTH ? "discoveryBox discoveryBoxDoubleSized" : "discoveryBox";

		return (
			<div id={cat+"Box"} 
				ref={this.discRef}
				className={boxClass+this.state.currentAnimation} 
				onAnimationEnd={this.endDiscAnimation}>
				<DiscTitleBar 	
					endTitleAnimation={this.endTitleAnimation}
					animate={this.state.titleAnimation}
					icon={iconArray[upgradeIndex] || iconArray[iconArray.length-1]}
					cat={cat}
					titleText={D[cat].title[upgradeIndex]}/>
				<DiscText 
					cat={cat}
					upgrades={this.props.upgrades}
					numEntries={entriesArray.length}
					meatMult={this.props.meatMult}
					/>
				<DiscInput 
					cat={this.props.cat} 
					place={D[cat].placeholderText[upgradeIndex]}
					verify={D[cat].verify}
					upgrades={upgradeIndex}
					addItem={this.addItem} 
					index={this.props.index}
					startPulse={this.startGoodPulseAnimation}
					startBadPulse={this.startBadPulseAnimation}
					entries={this.props.entries}
					addToEntries={this.props.addToEntries}
					/>
				<DiscList 
					cat={cat} 
					entries={entriesArray}
					goodPulse={this.state.goodPulsingItems}
					badPulse={this.state.badPulsingItems}
					endGoodPulse={this.endGoodPulseAnimation}
					endBadPulse={this.endBadPulseAnimation}
					/>

			</div>
		);
	}
}

export default Discovery;