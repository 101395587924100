import React from 'react';
import Discovery from './Discovery/Discovery';
import './Discoveries.css';
import { C } from '../../constants/DevSwitch.js';

import { Flipper, Flipped } from 'react-flip-toolkit';

const Discoveries = ( {addToEntries, unlocks, upgrades, meatMult, entries, justLoaded} ) => {

	let unlocksArray = [];
	if (unlocks !== "") {
		unlocksArray = unlocks.split(" ");
	}

	const doubleWidths = unlocksArray.filter(cat => entries[cat].length>C.DISCOVERYBOX_DOUBLEWIDTH).join("--");

	let display = unlocksArray.map((cat, index) =>
		<Flipped flipId={cat} key={cat}> 
			<div style={{order: 99-index}}>
				<Discovery 
					cat={cat}
					entries={entries[cat].join(" ,")} 
					addToEntries={addToEntries}
					upgrades={upgrades[cat]}
					meatMult={meatMult}
					index={99-index}
					justLoaded={justLoaded} />
			</div>
		</Flipped>
		);

	return (
		<Flipper flipKey={unlocks+doubleWidths} >
			<div className="discoveriesContainer">
				{display}
			</div>
		</Flipper>
	);

}


export default Discoveries;