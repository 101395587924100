import React from 'react';
import './MusicMenuPlayer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay, faStepForward, faStepBackward } from '@fortawesome/pro-solid-svg-icons';

const MusicMenuPlayer = ( {playToggle, playing, song, fullButtonDisplay, nextSong, prevSong} ) => {

	const playOrPauseIcon = playing ? faPause : faPlay;
	const musicPlayerButtonDisplayRules = fullButtonDisplay ? "musicPlayerButton" : "musicPlayerButton musicPlayerButtonHidden";

	const playButton = <button className="musicPlayerButton" onClick={playToggle}>
							<FontAwesomeIcon  	className="musicPlayerIcon" 
		                        				icon={playOrPauseIcon} />
						</button>

	const backButton = 	<button className={musicPlayerButtonDisplayRules} onClick={prevSong}>
							<FontAwesomeIcon  	className="musicPlayerIcon" 
    											icon={faStepBackward} />
						</button> 


	const forwardButton = <button className={musicPlayerButtonDisplayRules} onClick={nextSong}>
							<FontAwesomeIcon  	className="musicPlayerIcon" 
    											icon={faStepForward} />
						</button> 

	return (
			<div className="musicMenuPlayer">
				<div className="musicMenuPlayerFlexContainer">
					{song}
					<div className="musicMenuPlayerButtons">
						{backButton}
						{playButton}
						{forwardButton}
					</div>
				</div>
			</div>
		);

}

export default MusicMenuPlayer;
