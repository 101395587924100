import React from 'react';
import ResearchMenuRow from './ResearchMenuRow/ResearchMenuRow';
import { P } from '../../../../constants/DevSwitch'
import classNames from 'classnames';
import './ResearchMenu.css';


const ResearchMenu = ( {reveal, cats, inventory, numUpgrades, increaseCatUpgrades, closeConsoleButton, removeFromInventory, updateTitle, jambandPurchased, revealMusicButton, changeTutorial} ) => {

	const convertToRows = (row) => {
		return (
			<ResearchMenuRow 	cat={row} 
								key={row+"PurchaseRow"}
								inventory={inventory}
								numUpgrades={numUpgrades}
          						increaseCatUpgrades={increaseCatUpgrades}
          						closeConsoleButton={closeConsoleButton}
          						removeFromInventory={removeFromInventory}
          						updateTitle={updateTitle}
          						revealMusicButton={revealMusicButton}
          						changeTutorial={changeTutorial} />
			);
	}
	const purchaseRowsToShow = [];

	// conditions for determining which purchaseRows to reveal
	//========================================================

	if ((cats.includes("fsh") && cats.includes("trp")) && inventory.crop>=P.revealPrices.jamband && !jambandPurchased) {
		purchaseRowsToShow.push("jamband")
	}
	if (cats.includes("ber") && !cats.includes("fsh")) {
		purchaseRowsToShow.push("fsh");
	}
	if (cats.includes("ber") && !cats.includes("trp")) {
		purchaseRowsToShow.push("trp");
	}
	if (numUpgrades.ber<2 && (inventory.meat>P.revealPrices.ber2 || (cats.includes("fsh")&&cats.includes("trp")) )) {	
		purchaseRowsToShow.push("ber");
	}
	if (numUpgrades.ber===2 && !cats.includes("hnt")) {
		purchaseRowsToShow.push("hnt");
	}
	if (cats.includes("hnt") && numUpgrades.fsh===1) {
		purchaseRowsToShow.push("fsh");
	}
	if (cats.includes("hnt") && numUpgrades.trp===1) {
		purchaseRowsToShow.push("trp");
	}
	if (numUpgrades.hnt===1 && (inventory.crop>P.revealPrices.hnt2 || numUpgrades.fsh===2 || numUpgrades.trp===2)) { 			
		purchaseRowsToShow.push("hnt");
	}
	if (numUpgrades.hnt===2 && !cats.includes("dom")) {
		purchaseRowsToShow.push("dom");
	}
	if (numUpgrades.hnt===2 && !cats.includes("hor")) {
		purchaseRowsToShow.push("hor");
	}
	if (numUpgrades.hnt===2 && !cats.includes("bee")) {
		purchaseRowsToShow.push("bee");
	}
	if (numUpgrades.ber===2 && cats.includes("bee") && cats.includes("hor") && numUpgrades.fsh===2 && numUpgrades.trp===2) {
		purchaseRowsToShow.push("ber");
	}
	if (!cats.includes("pas") && numUpgrades.ber===3) {
		purchaseRowsToShow.push("pas");
	}

	//========================================================

	let rows = purchaseRowsToShow.map(convertToRows);  			

	if (rows.length === 0) {
		rows = <div className="noIdeas">
					<p>You stare at your jam flask, but you don't have any ideas to pursue.</p>
					<p>Keep collecting goods!</p>
					<p>Maybe you'll think of something clever.</p>
				</div>
	}

	let classes = classNames("consoleButtonMenu", "researchButtonMenu", reveal && "open");

	return (
			<div className={classes}>
				{rows}
			</div>
		);

}

export default ResearchMenu;