import WistfulSummer from '../audio/wistfulsummer.mp3';
import BriskFashion from '../audio/briskfashion.mp3';
import HotDog from '../audio/hotdog.mp3';
import HiHoCowboy from '../audio/hihocowboy.mp3';
import SwingNTonic from '../audio/swingntonic.mp3';

import { devMode } from './DevSwitch';

const Songs = {  

	summer: {
		audiofile: WistfulSummer,
		title: "Wistful Summer",
		description: "A sunny jam of growth and harvest",
		bpm: 98,
		rockspeed: 240,
		cost: [10, "ber"],
	},


	brisk: {
		audiofile: BriskFashion,
		title: "Brisk Fashion",
		description: "A jam that evokes a cat on the prowl",
		bpm: 128,
		rockspeed: 120,
		cost: [15, "trp", 8, "fsh", 5, "hnt"],
	},


	hotdog: {
		audiofile: HotDog,
		title: "Hot Dog",
		description: "A jam that brings out your animal nature",
		bpm: 127,
		rockspeed: 120,
		cost: [10, "hnt", 20, "dom"],
	},


	cowboy: {
		audiofile: HiHoCowboy,
		title: "Hi Ho Cowboy",
		description: "A valiant jam about courage on the range",
		bpm: 132,
		rockspeed: 240,
		cost: [15, "hor"],
	},


	swing: {
		audiofile: SwingNTonic,
		title: "Swing N Tonic",
		description: "Tipsy romance in a jazzy, jammy speakeasy",
		bpm: 110,
		rockspeed: 120,
		cost: [20, "bee"],
	}
}


/***********************************************/


if (devMode) {
	for (let song in Songs) {
		Songs[song].cost = [0, "ber"];
	}
}


export default Songs;