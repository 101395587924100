import React from 'react';
import './DiscList.css';

const DiscList = ( {cat, entries, goodPulse, badPulse, endGoodPulse, endBadPulse} ) => {

	let endPulse = () => {};

	let convertToListItem = (word, index, length) => {
		let classes = "listItem";
		let dot = <li className="discListDot">•</li>
		if (index===(length-1)) {
			dot = null;
		}
		if (goodPulse.includes(word)) {
			classes+=" pulse"; 
			endPulse = endGoodPulse;
		}
		if (badPulse.includes(word)) {
			classes+=" badpulse";
			endPulse = endBadPulse;
		}
		return 	<span key={word+cat} >
					<li className={classes} onAnimationEnd={endPulse}>{word}</li>
					{dot}
				</span>;
	}

	let list = entries.map((word, index) => convertToListItem(word, index, entries.length));

	return (
		<>
			<h4 style={{marginLeft: "10px", marginTop: "25px"}}>Discoveries</h4>
			<ul className="discoveryList" id={cat+"List"}>
				{list}
			</ul>
		</>
	);
}

export default DiscList;